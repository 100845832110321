import { GroupSchema } from 'api/backendApi'
import { FC, useState, createContext, useContext } from 'react'

// Contextオブジェクトを生成する
// 無駄なレンダリングを避けるため、値とsetterに分けてcontextを定義
// https://zenn.dev/yuta_ura/articles/react-context-api
const groupListContext = createContext<GroupSchema[]>([])
const setGroupListContext = createContext<
  React.Dispatch<React.SetStateAction<GroupSchema[]>>
>(() => undefined)

// 生成したContextオブジェクトのProviderを定義する
export const GroupListProvider: FC = ({ children }) => {
  const [groupList, setGroupList] = useState<GroupSchema[]>([])

  return (
    <groupListContext.Provider value={groupList}>
      <setGroupListContext.Provider value={setGroupList}>
        {children}
      </setGroupListContext.Provider>
    </groupListContext.Provider>
  )
}

export const useGroupList = (): GroupSchema[] => useContext(groupListContext)
export const useSetGroupList = (): React.Dispatch<
  React.SetStateAction<GroupSchema[]>
> => useContext(setGroupListContext)
