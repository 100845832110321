import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// URLパラメータを取得するhook群
export interface UseTypeNameParamReturn {
  typeName: string
  setTypeName: React.Dispatch<React.SetStateAction<string>>
}
// URLからtypeNameを取得する関数
export function useTypeNameParam(): UseTypeNameParamReturn {
  const [response, setResponse] = useState<string>('')
  // URLから種別に対応したパラメータを取得
  const { typeName } = useParams<{ typeName: string }>()
  useEffect(() => {
    if (typeName == null) {
      throw new Error('typeName is undefined.')
    }
    setResponse(typeName)
  }, [typeName])

  return { typeName: response, setTypeName: setResponse }
}

export interface useGroupIdParamReturn {
  groupId: number
}
// URLからgroupIdを取得する関数
export function useGroupIdParam(): useGroupIdParamReturn {
  const { groupId } = useParams<{ groupId: string }>()
  return { groupId: Number(groupId) }
}

export interface useEventIdParamReturn {
  eventId: number
  setEventId: React.Dispatch<React.SetStateAction<number>>
}
// URLからeventIdを取得する関数
export function useEventIdParam(): useEventIdParamReturn {
  const [response, setResponse] = useState<number>(0)

  // URLからeventIdを取得
  const { eventId } = useParams<{ eventId: string }>()
  useEffect(() => {
    if (eventId == null) {
      throw new Error('eventId is undefined.')
    }
    setResponse(Number(eventId))

    return () => {
      setResponse(0)
    }
  }, [eventId])

  return { eventId: response, setEventId: setResponse }
}
