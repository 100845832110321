import { useState, useEffect } from 'react'
import { useInspectionEvents } from './useInspectionEvents'

export const useIsApproved = (eventId: number): boolean => {
  const { inspectionEvents } = useInspectionEvents({ maxEvents: 100 })
  const [isApproved, setIsApproved] = useState(true)

  useEffect(() => {
    const event = inspectionEvents.find((event) => event.id === eventId)
    if (event != null) {
      setIsApproved(Boolean(event?.metadata?.isApproved) ?? true)
    }
  }, [inspectionEvents, eventId])

  return isApproved
}
