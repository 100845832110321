import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Button from '@mui/material/Button'
import { useFirstAndLastGroupId } from 'hooks/useFirstAndLastGroupId'
import { useGroupIdParam } from 'hooks/useUrlParam'


export interface FooterButtonsProps {
  previousTaskButtonHandler: () => void
  nextTaskButtonHandler: () => void
  groupListButtonHandler: () => void
}

export const FooterButtons: React.FC<FooterButtonsProps> = (props) => {
  const { firstGroupId, lastGroupId } = useFirstAndLastGroupId()
  const { groupId } = useGroupIdParam()

  // 前のタスク、次のタスクボタンのable/disableの管理
  const [isDisablePreviousTaskButton, setIsDisablePreviousTaskButton] =
    useState(false)
  useEffect(() => {
    setIsDisablePreviousTaskButton(
      firstGroupId == null ||
        lastGroupId == null ||
        Number.isNaN(groupId) ||
        groupId <= firstGroupId
    )
  }, [firstGroupId, groupId, lastGroupId])
  const [isDisableNextTaskButton, setIsDisableNextTaskButton] = useState(false)
  useEffect(() => {
    setIsDisableNextTaskButton(
      firstGroupId == null ||
        lastGroupId == null ||
        Number.isNaN(groupId) ||
        groupId >= lastGroupId
    )
  }, [firstGroupId, groupId, lastGroupId])

  return (
    <Grid container justifyContent="center" direction="column" spacing={1}>
      <Grid item>
        <Grid container spacing={1} justifyContent="center" direction="row">
          <Grid item>
            <Button
              variant="contained"
              disabled={isDisablePreviousTaskButton}
              onClick={props.previousTaskButtonHandler}
            >
              前のタスク
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={props.groupListButtonHandler}>
              グループ一覧
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={isDisableNextTaskButton}
              onClick={props.nextTaskButtonHandler}
            >
              次のタスク
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
