import React, { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ItemsSchema } from 'api/backendApi'
import { UpdateMachineTaskListProps } from 'hooks/useMachineTaskList'

export interface ChoiceInputTaskProps {
  task: ItemsSchema
  taskIndex: number
  machineIndex: number
  onTimeseriesChangeFunc: (props: UpdateMachineTaskListProps) => void
}

export const ChoiceInputTask: React.FC<ChoiceInputTaskProps> = (props) => {
  const [digitalCurrentValue, setDigitalCurrentValue] = useState<string>(
    // props.task.valueがnull or undefinedの場合、空文字列を設定する
    props.task.value != null ? props.task.value.toString() : ''
  )
  useEffect(() => {
    if (props.task.value != null) {
      setDigitalCurrentValue(props.task.value.toString())
    } else {
      setDigitalCurrentValue('')
    }
  }, [props.task.value])

  const handleChange = (event: SelectChangeEvent): void => {
    // 画面表示用の変数を修正
    setDigitalCurrentValue(event.target.value)
    // TaskList.tsxで持つtimeseriesResultListを修正
    props.onTimeseriesChangeFunc({
      taskIndex: props.taskIndex,
      machineIndex: props.machineIndex,
      newValue: Number(event.target.value)
    })
  }

  // 0-9に対応する表示を意味する列名
  // keyofをつけないと、props.task[keyName]でエラーが発生する
  const displayTable: Array<keyof ItemsSchema> = [
    'zeroDisplay',
    'oneDisplay',
    'twoDisplay',
    'threeDisplay',
    'fourDisplay',
    'fiveDisplay',
    'sixDisplay',
    'sevenDisplay',
    'eightDisplay',
    'nineDisplay'
  ]

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">選択</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        data-testid="choice-input-task"
        value={digitalCurrentValue}
        label="選択"
        onChange={handleChange}
        // defaultValueはundefinedを受け取れるがnullを受け取れないため、resultValueは初期状態としてundefinedとする
      >
        {/* task内でxxDisplayが定義されていた場合のみ、選択肢として表示する */}
        {(() => {
          const menuItems: JSX.Element[] = []
          // eslint-disable-next-line array-callback-return
          displayTable.map((keyName, index) => {
            if (typeof props.task[keyName] !== 'undefined') {
              menuItems.push(
                <MenuItem value={index} key={index}>
                  {props.task[keyName]}
                </MenuItem>
              )
            }
          })
          return menuItems
        })()}
      </Select>
    </FormControl>
  )
}
