import { FC, useState, createContext, useContext } from 'react'

// Contextオブジェクトを生成する
// 無駄なレンダリングを避けるため、値とsetterに分けてcontextを定義
// https://zenn.dev/yuta_ura/articles/react-context-api
export const openSuccessContext = createContext<boolean>(false)
export const setOpenSuccessContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => undefined)
export const openFailContext = createContext<boolean>(false)
export const setOpenFailContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => undefined)

// 生成したContextオブジェクトのProviderを定義する
export const OpenSnackBarProvider: FC = ({ children }) => {
  const [openSuccess, setOpenSuccess] = useState<boolean>(false)
  const [openFail, setOpenFail] = useState<boolean>(false)

  return (
    <openSuccessContext.Provider value={openSuccess}>
      <setOpenSuccessContext.Provider value={setOpenSuccess}>
        <openFailContext.Provider value={openFail}>
          <setOpenFailContext.Provider value={setOpenFail}>
            {children}
          </setOpenFailContext.Provider>
        </openFailContext.Provider>
      </setOpenSuccessContext.Provider>
    </openSuccessContext.Provider>
  )
}

export const useOpenSuccess = (): boolean => useContext(openSuccessContext)
export const useSetOpenSuccess = (): React.Dispatch<
  React.SetStateAction<boolean>
> => useContext(setOpenSuccessContext)
export const useOpenFail = (): boolean => useContext(openFailContext)
export const useSetOpenFail = (): React.Dispatch<
  React.SetStateAction<boolean>
> => useContext(setOpenFailContext)
