import { useInspectionTypes } from 'contexts/InspectionTypesContext'
import { useEffect, useState } from 'react'
import { useTargetIndex } from './useTargetIndex'

interface useVersionIdReturn {
  versionId: number | undefined
}

/**
 * 選択された点検のバージョンIDを取得するフック
 *
 * @return {useVersionIdReturn}
 */
export const useVersionId = (): useVersionIdReturn => {
  const inspectionTypes = useInspectionTypes()
  const { targetIndex } = useTargetIndex()
  const [versionId, setVersionId] = useState<number>()
  // versionId, targetIndexの設定
  useEffect(() => {
    if (
      // targetIndex = 0のパターンも存在するため、!targetIndexでは判断しない
      targetIndex == null ||
      inspectionTypes.length === 0 ||
      inspectionTypes[targetIndex]?.versionId == null
    ) {
      return
    }
    setVersionId(inspectionTypes[targetIndex].versionId)
  }, [inspectionTypes, targetIndex])

  return {
    versionId
  }
}
