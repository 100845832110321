import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

const MAX_RETRY = 3
const RETRY_DELAY = 1000 // Delay in ms

export const retryAxios = async (
  requestConfig: AxiosRequestConfig,
  retryCount: number = 0
): Promise<AxiosResponse> => {
  try {
    const response = await axios(requestConfig)
    return response
  } catch (error) {
    if (retryCount >= MAX_RETRY) {
      console.log(JSON.stringify(error))
      throw new Error(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `API Request failed after ${MAX_RETRY} retries. error: ${
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          (error as any).message
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        } ${(error as any).config.url}`
      )
    }

    console.warn(
      `API Request failed. Retrying (${retryCount + 1} / ${MAX_RETRY})`
    )

    await new Promise((resolve) =>
      setTimeout(resolve, RETRY_DELAY * (retryCount + 1))
    )

    // eslint-disable-next-line @typescript-eslint/return-await
    return retryAxios(requestConfig, retryCount + 1)
  }
}
