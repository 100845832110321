import React, { useEffect, useState } from 'react'
import { GroupSchema, getGroups } from 'api/backendApi'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import { useAuthUser } from './useAuthUser'
import { useVersionId } from './useVersionId'

interface UseGroupListReturn {
  groupList: GroupSchema[]
  setGroupList: React.Dispatch<React.SetStateAction<GroupSchema[]>>
}

export const useGroupList = (): UseGroupListReturn => {
  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    if (newIdToken === '') {
      return
    }
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])

  const { versionId } = useVersionId()

  interface fetchGroupsProps {
    versionId: number | undefined
    idToken: string
  }

  const [groupList, setGroupList] = useState<GroupSchema[]>([])
  useEffect(() => {
    const fetchGroups = async (props: fetchGroupsProps): Promise<void> => {
      // 初回のレンダリング時にinspectionTypesなどが空のタイミングで呼ばれるため、エラーハンドリング
      if (props.versionId == null || props.idToken === '') {
        return
      }

      // 種別ID元にグループ一覧を取得
      const result = await getGroups({
        versionId: props.versionId,
        idToken: props.idToken
      }).catch((error) => {
        console.log(error)
        throw new Error('getGroups API is failed.')
      })

      // return result
      setGroupList(result)
    }
    fetchGroups({ versionId, idToken }).catch((error) => {
      console.log(error)
      throw new Error('fetchGtoups is failed')
    })

    return () => {
      setGroupList([])
    }
  }, [idToken, versionId])

  return {
    groupList,
    setGroupList
  }
}
