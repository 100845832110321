import { CogniteEvent } from '@cognite/sdk'
import { FC, useState, createContext, useContext } from 'react'

// Contextオブジェクトを生成する
// 無駄なレンダリングを避けるため、値とsetterに分けてcontextを定義
// https://zenn.dev/yuta_ura/articles/react-context-api
const ModifyStartEventContext = createContext<CogniteEvent | null>(null)
const SetModifyStartEventContext = createContext<
  React.Dispatch<React.SetStateAction<CogniteEvent | null>>
>(() => undefined)

// 生成したContextオブジェクトのProviderを定義する
export const ModifyStartEventProvider: FC = ({ children }) => {
  const [modifyStartEvent, setModifyStartEvent] = useState<CogniteEvent | null>(
    null
  )

  return (
    <ModifyStartEventContext.Provider value={modifyStartEvent}>
      <SetModifyStartEventContext.Provider value={setModifyStartEvent}>
        {children}
      </SetModifyStartEventContext.Provider>
    </ModifyStartEventContext.Provider>
  )
}

export const useModifyStartEvent = (): CogniteEvent | null =>
  useContext(ModifyStartEventContext)
export const useSetModifyStartEvent = (): React.Dispatch<
  React.SetStateAction<CogniteEvent | null>
> => useContext(SetModifyStartEventContext)
