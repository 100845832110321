import { getLatestDatapoint } from 'api/cogniteApi'
import { useQuery } from 'react-query'

interface UseLatestValueProps {
  externalId: string
}

interface UseLatestValueReturn {
  latestValue: string
  isLoading: boolean
  error: unknown
}

const fetchLatestValue = async (externalId: string): Promise<string> => {
  const result = await getLatestDatapoint({
    externalId
  })
  if (typeof result.datapoints[0] !== 'undefined') {
    // 日時及び値をstateに保存
    return String(result.datapoints[0].value)
  }
  return ''
}

/**
 *  DCSデータ表示に用いる最新の点検結果を取得するフック
 *
 * @param {UseLatestValueProps} props
 * @return {UseLatestValueReturn}
 */
export const useLatestValue = (
  props: UseLatestValueProps
): UseLatestValueReturn => {
  // DCSデータ表示に用いる最新の点検結果を保存。
  const { data, isLoading, error } = useQuery(
    `latestValue-${props.externalId}`,
    async () => await fetchLatestValue(props.externalId)
  )

  return {
    latestValue: data != null ? data : '',
    isLoading,
    error
  }
}
