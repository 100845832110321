import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react'
import { SuccessSnackBar } from 'components/Atoms/SuccessSnackBar'
import { FailSnackBar } from 'components/Atoms/FailSnackBar'

export interface CogniteRegistrationSnackBarProps {
  openSuccess: boolean
  setOpenSuccess: Dispatch<SetStateAction<boolean>>
  openFail: boolean
  setOpenFail: Dispatch<SetStateAction<boolean>>
}

export const CogniteRegistrationSnackBar: React.FC<
  CogniteRegistrationSnackBarProps
> = (props) => {
  // 成功時SnackBar削除時のコールバック
  const handleCloseSuccess = (
    _event?: SyntheticEvent,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    props.setOpenSuccess(false)
  }
  // 失敗時SnackBar削除時のコールバック
  const handleCloseFail = (_event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }
    props.setOpenFail(false)
  }

  return (
    <>
      <SuccessSnackBar
        message={'Cogniteへの登録が完了しました'}
        open={props.openSuccess}
        setOpen={props.setOpenSuccess}
        handleClose={handleCloseSuccess}
      />
      <FailSnackBar
        message={'Cogniteへの登録に失敗しました'}
        open={props.openFail}
        setOpen={props.setOpenFail}
        handleClose={handleCloseFail}
      />
    </>
  )
}
