import React, { SyntheticEvent } from 'react'
import { useOpenFail, useSetOpenFail } from 'contexts/OpenSnackBarContext'
import { FailSnackBar } from 'components/Atoms/FailSnackBar'

interface DebugSnackBarProps {
  message?: string
}

export const DebugSnackBar: React.FC<DebugSnackBarProps> = (props) => {
  // cogniteへの登録が成功/失敗した場合にsnackbarによる通知を行うための変数群
  const openFail = useOpenFail()
  const setOpenFail = useSetOpenFail()

  // 失敗時のSnackBar削除時のコールバック
  const handleCloseFail = (_event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }
    setOpenFail(false)
  }

  return (
    <>
      <FailSnackBar
        message={`${props.message ?? ''}`}
        open={openFail}
        setOpen={setOpenFail}
        handleClose={handleCloseFail}
        duration={5000}
        style={{ marginBottom: '50px' }}
      />
    </>
  )
}
