import { CogniteEvent } from '@cognite/sdk'
import { getInspectionStartEvent } from 'api/cogniteApi'
import { useState } from 'react'
// import { useEffect, useState } from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery
} from 'react-query'

export interface useInspectionEventsProps {
  metadata?: { [key: string]: string }
  maxEvents: number
}

export interface useInspectionEventsReturn {
  isLoading: boolean
  error: unknown
  inspectionEvents: CogniteEvent[]
  loadMore: () => Promise<void>
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<CogniteEvent[], unknown>>
}

export const useInspectionEvents = (
  props: useInspectionEventsProps
): useInspectionEventsReturn => {
  const [inspectionEvents, setInspectionEvents] = useState<CogniteEvent[]>([])
  const [cursor, setCursor] = useState<string | undefined>(undefined)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const searchInspectionEvents = async (): Promise<CogniteEvent[]> => {
    const { items: result, nextCursor } = await getInspectionStartEvent(
      100,
      cursor
    )
    if (result.length === 0) {
      return []
    }

    let eventList = result
    // metadata条件によるフィルタ処理
    if (props.metadata != null) {
      // オブジェクトのforループ処理
      Object.keys(props.metadata).forEach((key) => {
        eventList = eventList.filter((event) => {
          return !!(
            event.metadata?.[key] != null &&
            event.metadata[key] === props.metadata?.[key]
          )
        })
      })
    }

    setCursor(nextCursor)

    return eventList
  }

  const { isLoading, error, refetch } = useQuery(
    'inspectionEvents',
    searchInspectionEvents,
    {
      // tracked を指定
      notifyOnChangeProps: 'tracked',
      onSuccess: (data) => {
        setInspectionEvents((prev) => [...prev, ...data])
      }
    }
  )

  const loadMore = async (): Promise<void> => {
    if (cursor == null || isLoadingMore) {
      return
    }

    setIsLoadingMore(true)
    try {
      const moreEvents = await searchInspectionEvents()
      setInspectionEvents((prev) => [...prev, ...moreEvents])
    } finally {
      setIsLoadingMore(false)
    }
  }

  return {
    isLoading,
    error,
    inspectionEvents,
    loadMore,
    refetch
    // setInspectionEvents: setInspectionEventsList,
  }
}
