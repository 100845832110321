import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'

export interface useAuthUserReturn {
  newIdToken: string
  account: AccountInfo
  instance: IPublicClientApplication
  groups: string[]
}

export const useAuthUser = (): useAuthUserReturn => {
  const { instance, accounts } = useMsal()
  const account = accounts[0]
  const [newIdToken, setNewIdToken] = useState<string>('')
  const [groups, setGroups] = useState<string[]>([])

  useEffect(() => {
    const requestIdToken = (): void => {
      const request = {
        scopes: ['openid'],
        account
      }

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setNewIdToken(response.idToken)
        })
        .catch((error) => {
          console.log(error)
          instance
            .acquireTokenPopup(request)
            .then((response) => {
              setNewIdToken(response.idToken)
            })
            .catch((error) => {
              console.log(error)
              throw new Error('acquireTokenPopup is failed')
            })
        })
    }
    requestIdToken()
  }, [account, instance])

  useEffect(() => {
    try {
      if (newIdToken === '') {
        return
      }
      const base64Url = newIdToken.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = JSON.parse(
        decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )
      )
      setGroups(jsonPayload.groups)
    } catch (error) {
      console.log(error)
    }
  }, [newIdToken])

  return { newIdToken, account, instance, groups }
}
