import { CogniteEvent } from '@cognite/sdk/dist/src'
import { Paper, ListItem, ListItemText, Button, Grid } from '@material-ui/core'
import { getInspectionTypeName } from 'api/backendApi'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import { useInspectionTypes } from 'contexts/InspectionTypesContext'
import { useAuthUser } from 'hooks/useAuthUser'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface InspectionEventCardProps {
  startEvent: CogniteEvent
  onClickModification: (startEvent: CogniteEvent) => Promise<void>
  isModificationClicked: boolean
}

// 点検情報を表示するカードcomponent
export const InspectionEventCard: React.FC<InspectionEventCardProps> = (
  props
) => {
  const [versionId, setVersionId] = useState<string>('')
  const [inspectionTypeName, setInspectionTypeName] = useState<string>('')
  const inspectionTypes = useInspectionTypes()
  const [isApproved, setIsApproved] = useState<boolean>(true)
  const navigate = useNavigate()

  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])

  // ボタンクリック時のハンドラ
  const onClick = (startEvent: CogniteEvent): void => {
    navigate(`/inspectionResultsList/${startEvent.id}`)
  }

  useEffect(() => {
    if (
      props.startEvent?.metadata != null &&
      props.startEvent.metadata.versionId !== ''
    ) {
      setVersionId(props.startEvent.metadata.versionId)
    }
  }, [props.startEvent?.metadata])

  useEffect(() => {
    if (versionId === '' || inspectionTypes.length === 0 || idToken === '') {
      return
    }

    const setTypeName = async (): Promise<void> => {
      const type = (
        await getInspectionTypeName({
          idToken,
          versionId
        })
      )[0]
      const typeName = type.type
      setInspectionTypeName(typeName)
    }
    void setTypeName()
  }, [idToken, inspectionTypes, versionId])

  useEffect(() => {
    if (
      props.startEvent.metadata != null &&
      props.startEvent.metadata?.isApproved === 'true'
    ) {
      setIsApproved(true)
    } else {
      setIsApproved(false)
    }
  }, [props.startEvent?.metadata])

  // 点検開始イベントにalertflagが含まれているかをもとに、異常有り or ''をreturnする
  const getAbnormalityDescriptions = (): string => {
    if (props.startEvent.metadata?.alertflag != null) {
      return '異常有り'
    } else {
      return ''
    }
  }

  if (props.startEvent.startTime == null) {
    throw new Error('Event schema is invalid.')
  }
  // Dateオブジェクトをそのまま表示は出来ないため、string型に変換する
  // 日付
  const startDate = new Date(props.startEvent.startTime).toLocaleDateString(
    'ja-JP'
  )
  // 時刻
  const startTime = new Date(props.startEvent.startTime).toLocaleTimeString(
    'ja-JP',
    { hour12: false }
  )
  // 異常有無の説明
  const abnormalityDescrioption: string = getAbnormalityDescriptions()
  if (
    props.startEvent.metadata?.inspectorName != null &&
    props.startEvent.metadata?.inspectorName !== ''
  ) {
    const inspectorName = props.startEvent.metadata.inspectorName
    return (
      <Paper style={{ margin: '16px 0px' }}>
        <ListItem>
          <Grid container direction="column">
            <Grid item>
              <ListItemText
                primary={`${startDate} ${startTime}  ${inspectionTypeName}`}
                secondary={`${inspectorName}        ${abnormalityDescrioption}`}
                primaryTypographyProps={{
                  variant: 'h5'
                }}
              />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    onClick={() => onClick(props.startEvent)}
                    color="inherit"
                  >
                    結果
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      void props.onClickModification(props.startEvent)
                    }}
                    disabled={props.isModificationClicked || isApproved}
                    color="secondary"
                  >
                    修正
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </Paper>
    )
  } else {
    throw new Error('metadata in cognite events is invalid.')
  }
}
