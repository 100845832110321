import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuIcon from '@mui/icons-material/Menu'
import FactoryIcon from '@mui/icons-material/Factory'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { useEnvironmentText } from 'hooks/useEnvironmentText'

export const AppBarComponent: React.FC = () => {
  const environmentText = useEnvironmentText()
  const { accounts } = useMsal()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const navigate = useNavigate()
  // HOMEページに遷移
  const handleToTopPage = (): void => {
    setAnchorEl(null)
    navigate('/')
  }

  return (
    <Box displayPrint="none" sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-butoon'
            }}
          >
            <MenuItem onClick={handleToTopPage}>HOMEへ戻る</MenuItem>
            <MenuItem disabled onClick={handleToTopPage}>
              管理画面
            </MenuItem>
            <MenuItem disabled onClick={handleToTopPage}>
              ログアウト
            </MenuItem>
          </Menu>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {environmentText}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {accounts[0].name}
              </Typography>
            </Grid>
          </Grid>
          <FactoryIcon />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
