import React from 'react'
import { Grid } from '@material-ui/core'
import Button from '@mui/material/Button'
import { TypeIdSchema, RegistrationflagType } from 'api/backendApi'
import {
  handleDeleteButtonProps,
  handleRegisterButtonProps
} from 'components/Pages/Home'

export interface StartButtonsProps {
  handleNewInspectionButton: (props: TypeIdSchema) => void
  handleContinueInspectionButton: (props: TypeIdSchema) => void
  handleRegisterButton: (props: handleRegisterButtonProps) => void
  handleDeleteButton: (props: handleDeleteButtonProps) => void
  typeIdElement: TypeIdSchema
  isDisableButtons: boolean
}

export const StartButtons: React.FC<StartButtonsProps> = (props) => {
  if (
    props.typeIdElement.registrationflag === RegistrationflagType.Registered
  ) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={props.isDisableButtons}
            onClick={() => props.handleNewInspectionButton(props.typeIdElement)}
            sx={{ width: 210 }}
          >
            新規
          </Button>
        </Grid>
      </Grid>
    )
  } else if (
    props.typeIdElement.registrationflag === RegistrationflagType.NotRegistered
  ) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={props.isDisableButtons}
            onClick={() =>
              props.handleContinueInspectionButton(props.typeIdElement)
            }
            sx={{ width: 60 }}
          >
            再開
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            disabled={props.isDisableButtons}
            onClick={() =>
              props.handleRegisterButton({
                versionId: props.typeIdElement.versionId
              })
            }
            sx={{ width: 60 }}
          >
            登録
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="error"
            disabled={props.isDisableButtons}
            onClick={() =>
              props.handleDeleteButton({
                versionId: props.typeIdElement.versionId
              })
            }
            sx={{ width: 60 }}
          >
            削除
          </Button>
        </Grid>
      </Grid>
    )
  } else {
    throw new Error('registeredFlag is invalid.')
  }
}
