import React, { FC, useState, createContext, useContext } from 'react'

// Contextオブジェクトを生成する
// 無駄なレンダリングを避けるため、値とsetterに分けてcontextを定義
// https://zenn.dev/yuta_ura/articles/react-context-api
export const idTokenContext = createContext<string>('')
export const setIdTokenContext = createContext<
  React.Dispatch<React.SetStateAction<string>>
>(() => undefined)

// 生成したContextオブジェクトのProviderを定義する
export const IdTokenProvider: FC = ({ children }) => {
  const [idToken, setidToken] = useState<string>('')

  return (
    <idTokenContext.Provider value={idToken}>
      <setIdTokenContext.Provider value={setidToken}>
        {children}
      </setIdTokenContext.Provider>
    </idTokenContext.Provider>
  )
}

export const useIdToken = (): string => useContext(idTokenContext)
export const useSetIdToken = (): React.Dispatch<React.SetStateAction<string>> =>
  useContext(setIdTokenContext)
