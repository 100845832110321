import { GroupSchema } from 'api/backendApi'
import { useEffect, useState } from 'react'
import { useGroupList } from './useGroupList'

interface PlaceGroupList {
  [index: string]: GroupSchema[]
}

interface UsePlaceGroupListReturn {
  placeList: string[]
  placeGroupList: PlaceGroupList
}

export const usePlaceGroupList = (): UsePlaceGroupListReturn => {
  const { groupList } = useGroupList()

  const [placeList, setPlaceList] = useState<string[]>([])
  const [placeGroupList, setPlaceGroupList] = useState<PlaceGroupList>({})
  useEffect(() => {
    if (groupList.length === 0) {
      return
    }

    const tempPlaceGroupList: PlaceGroupList = {}
    const tempPlaceList: string[] = []
    groupList.forEach((group) => {
      if (!tempPlaceList.includes(group.placeName)) {
        tempPlaceList.push(group.placeName)
        tempPlaceGroupList[group.placeName] = []
      }
      tempPlaceGroupList[group.placeName].push(group)
    })
    setPlaceList(tempPlaceList)
    setPlaceGroupList(tempPlaceGroupList)

    return () => {
      setPlaceList([])
      setPlaceGroupList({})
    }
  }, [groupList])

  return {
    placeList,
    placeGroupList
  }
}
