import React from 'react'
import { LineWave } from 'react-loader-spinner'

export interface LoadingProps {
  isLoading: boolean
}

export const Loading: React.FC<LoadingProps> = (props) => {
  if (props.isLoading) {
    return (
      <section className="flex justify-center items-center h-screen">
        <div>
          <LineWave
            height="100"
            width="100"
            color="#0000CD"
            ariaLabel="line-wave"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
          <p className="text-center mt-3">Now loading...</p>
        </div>
      </section>
    )
  } else {
    return <></>
  }
}
