/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

interface CommonDialogProps {
  message: string
  isOpen: boolean
  isYesDisabled?: boolean
  doYes: () => void | Promise<void>
  doNo: () => void
}

const CommonDialog: React.FC<CommonDialogProps> = (props) => {
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    setOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={props.doNo}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>{props.message}</DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
          >
            <Grid item>
              <Button onClick={props.doNo} color="primary">
                No
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => props.doYes()}
                color="primary"
                disabled={props.isYesDisabled}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default CommonDialog
