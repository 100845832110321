import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import List from '@mui/material/List'
import Container from '@mui/material/Container'
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  ListItem,
  TextField,
  Skeleton
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTypeNameParam, useGroupIdParam } from 'hooks/useUrlParam'
import { IoMode } from 'api/backendApi'
import { TaskListItem } from 'components/Molecules/TaskListItem'
import { FooterButtons } from 'components/Molecules/FooterButtons'

import { useSetOpenFail, useSetOpenSuccess } from 'contexts/OpenSnackBarContext'
import { useMachineList } from 'hooks/useMachineList'
import { usePlaceName } from 'hooks/usePlaceName'
import { MachineNote, useMachineNotes } from 'hooks/useMachineNotes'
import { useFirstAndLastGroupId } from 'hooks/useFirstAndLastGroupId'
import {
  MachineTask,
  UpdateMachineTaskListProps
} from 'hooks/useMachineTaskList'
import CommonDialog from 'components/Molecules/CommonDialog'
import {
  updateCogniteEventDescriptionByMachineId,
  updateCogniteTimeseries
} from 'api/cogniteApi'
import { useModifyStartEvent } from 'contexts/ModifyStartEvent'
import { Timestamp } from '@cognite/sdk'
import { useModificationMachineTaskList } from 'hooks/useModificationMachineTaskList'
import { UpdateCogniteSnackBar } from 'components/Molecules/UpdateCogniteSnackBar'

interface MachineNoteInputProps {
  machineNotes: MachineNote[]
  setMachineNotes: React.Dispatch<React.SetStateAction<MachineNote[]>>
  machineIndex: number
}
/**
 * 備考入力欄のコンポーネント
 *
 * @param {MachineNoteInputProps} props
 * @return {JSX.Element}
 */
const MachineNoteInput: React.FC<MachineNoteInputProps> = (
  props: MachineNoteInputProps
) => {
  if (props.machineNotes?.[props.machineIndex] != null) {
    return (
      <Paper style={{ margin: '16px 0px' }}>
        <ListItem key={`${props.machineIndex}-notes`}>
          <Grid container direction="column">
            <Grid item>
              <InputLabel htmlFor="standard-adornment-amount">備考</InputLabel>
            </Grid>
            <Grid item>
              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <TextField
                  id="standard-adornment-amount"
                  value={props.machineNotes[props.machineIndex].note}
                  onChange={(event) => {
                    const newMachineNotes = props.machineNotes.map(
                      (machineNote, index) => {
                        // machineNotesのmachineIndex番目の要素を削除し、代わりにevent.targe.valueを挿入する
                        return index === props.machineIndex
                          ? {
                              ...machineNote,
                              note: event.target.value
                            }
                          : machineNote
                      }
                    )
                    props.setMachineNotes(newMachineNotes)
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </ListItem>
      </Paper>
    )
  } else {
    return <div></div>
  }
}

interface TaskCardsWithSkeletonProps {
  machineTaskList: MachineTask[]
  placeName: string
  updateMachineTaskList: (props: UpdateMachineTaskListProps) => void
  machineNotes: MachineNote[]
  setMachineNotes: React.Dispatch<React.SetStateAction<MachineNote[]>>
}
/**
 * 場所名、点検項目、備考入力欄を表示するコンポーネント
 *
 * @return {JSX.Element}
 */
const TaskCardsWithSkeleton: React.FC<TaskCardsWithSkeletonProps> = (
  props: TaskCardsWithSkeletonProps
) => {
  // 点検項目一覧のloadingが終わった場合
  if (
    props.machineTaskList?.[0]?.tasks != null &&
    props.machineTaskList[0].tasks.length > 0
  ) {
    return (
      <div>
        <Typography variant="h4">{`${props.placeName}`}</Typography>
        {props.machineTaskList.map((machine, machineIndex) => {
          return (
            <div>
              <Typography
                variant="h6"
                color={'#005FFF'}
                fontWeight="bold"
              >{`${machine.machineName}`}</Typography>
              {machine.machineMemo != null && (
                <Typography
                  variant="subtitle1"
                  color={'#005FFF'}
                  fontWeight="bolder"
                >{`${machine.machineMemo}`}</Typography>
              )}
              <List>
                {machine.tasks.map((task, taskIndex) => {
                  return (
                    <div>
                      <TaskListItem
                        task={task}
                        taskIndex={taskIndex}
                        machineIndex={machineIndex}
                        onTimeseriesChangeFunc={props.updateMachineTaskList}
                      />
                    </div>
                  )
                })}
                <MachineNoteInput
                  machineNotes={props.machineNotes}
                  machineIndex={machineIndex}
                  setMachineNotes={props.setMachineNotes}
                />
              </List>
            </div>
          )
        })}
      </div>
    )
  } else {
    // ローディング中の場合、skeletonを表示させる
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Skeleton variant="text" animation="wave" width={300} height={40} />
        </Grid>
        <Grid item>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width={300}
            height={100}
            sx={{ margin: 2 }}
          />
        </Grid>
      </Grid>
    )
  }
}

export const ModificationTaskList: React.FC = () => {
  // page遷移用
  const navigate = useNavigate()
  // URLパラメータ
  const { typeName } = useTypeNameParam()
  const startEvent = useModifyStartEvent()
  const { groupId } = useGroupIdParam()
  const { placeName } = usePlaceName({ groupId })
  const { firstGroupId, lastGroupId } = useFirstAndLastGroupId()
  const { machineList } = useMachineList()
  const { machineNotes, setMachineNotes } = useMachineNotes({
    machineList,
    startEvent
  })

  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(0)
  useEffect(() => {
    if (startEvent?.startTime == null || startEvent?.endTime == null) {
      return
    }

    const convertTimestamp2Number = (time: Timestamp): number => {
      if (typeof time === 'number') {
        return time
      } else {
        return time.getTime()
      }
    }
    setStart(convertTimestamp2Number(startEvent.startTime))
    setEnd(convertTimestamp2Number(startEvent.endTime))
  }, [startEvent?.endTime, startEvent?.startTime])

  const { machineTaskList, updateMachineTaskList } =
    useModificationMachineTaskList({
      machineList,
      start,
      end
    })

  // 入力された値でTimeseries, 備考の更新を行う
  const updateValue = async (): Promise<void> => {
    // 時系列データを更新するAPIコール
    const updateTimeseries = async (): Promise<void> => {
      if (startEvent?.startTime == null || startEvent?.endTime == null) {
        throw new Error(
          'startEvent is invalid (both startTime and endTime should be defined.)'
        )
      }
      if (machineTaskList?.length === 0) {
        return
      }

      await Promise.all(
        machineTaskList.map((machine) => {
          return machine.tasks.map(async (task) => {
            // mapなので必ずなにかをreturnする必要あり。valueがない場合はPromise<void>をreturn
            // value = 0の場合もあるので、task.value == nullでの判断は行わない
            if (task.value === null || task.value === undefined) {
              return await Promise.resolve()
            }
            return await updateCogniteTimeseries({
              externalId: task.cogniteExternalId,
              value: task.value,
              start,
              end
            })
          })
        })
      )
    }

    // 備考を修正するAPIコール
    const updateNotes = async (): Promise<void> => {
      if (machineNotes?.length === 0) {
        return
      }
      if (startEvent == null) {
        throw new Error('startEvent is invalid')
      }

      await Promise.all(
        machineNotes.map(async (machineNote) => {
          if (machineNote == null || machineNote.note === '') {
            return await Promise.resolve()
          }

          return await updateCogniteEventDescriptionByMachineId({
            startEvent,
            machineId: String(machineNote.machineId),
            value: machineNote.note,
            assetId: machineNote.assetId
          })
        })
      )
    }

    // 時系列データ、備考、時刻を同時に仮登録する
    await Promise.all([updateTimeseries(), updateNotes()]).catch((error) => {
      console.log(error)
      throw new Error('register result to cognite is failed')
    })
  }

  /**
   * 未入力の点検項目が存在するか確認する
   *
   * @return {boolean}
   */
  const isExistsUnenteredInput = (): boolean => {
    if (machineTaskList.length === 0) {
      throw new Error('machineTaskList is invalid')
    }

    // machineTaskList.machine.tasksの点検項目配列にvalue = undefinedなものがあるかを確認する。
    // 4行下のmachineに対するsome判定が一つでもtrueだった場合、unenteredFlagにはtrueが返る。
    const unenteredFlag = machineTaskList.some((machine) =>
      // 特定のmachineの持つtasks配列においてループを回し、
      // 各taskが以下条件を一つでも満たすかのtrue / falseを判定する。
      machine.tasks.some(
        (task) => task.ioMode === IoMode.Input && task.value == null
      )
    )
    return unenteredFlag
  }

  enum TaskPage {
    PreviousTask = -1,
    NextTask = 1
  }
  interface TransitionToDifferentTaskProps {
    relativePageNumber: TaskPage.PreviousTask | TaskPage.NextTask
  }
  // 仮登録及び次のタスク一覧ページへの遷移を行う
  const transitionToDifferentTask = (
    props: TransitionToDifferentTaskProps
  ): void => {
    if (firstGroupId == null || lastGroupId == null || typeName === '') {
      throw new Error('first / lastGroupId or typeName is null.')
    }
    // 後続のGroupがない状態でcallされた場合、なにもしない
    if (groupId >= lastGroupId) {
      return
    }
    updateValue()
      .then(() => {
        setOpenSuccess(true)
      })
      .catch((error) => {
        console.log(error)
        setOpenFail(true)
      })
    navigate(
      `/modification/inspectionInput/${typeName}/${
        groupId + props.relativePageNumber
      }`
    )
  }

  const [isOpenNextTaskCheckScreen, setIsOpenNextTaskCheckScreen] =
    useState(false)
  const openNextTaskCheckScreen = (): void => {
    setIsOpenNextTaskCheckScreen(true)
  }
  const closeNextTaskCheckScreen = (): void => {
    setIsOpenNextTaskCheckScreen(false)
  }
  /**
   * 次のタスクボタンを押した際のハンドラ。
   * 未入力の点検項目がなければ仮登録とページ遷移を行い、存在した場合は確認用のdialogを開く。
   *
   */
  const handleNextTask = (): void => {
    if (isExistsUnenteredInput()) {
      openNextTaskCheckScreen()
    } else {
      transitionToDifferentTask({ relativePageNumber: TaskPage.NextTask })
    }
  }

  // cogniteへの登録が成功/失敗した場合にsnackbarを表示させるsetter
  const setOpenSuccess = useSetOpenSuccess()
  const setOpenFail = useSetOpenFail()
  const [isOpenPreviousTaskCheckScreen, setIsOpenPreviousTaskCheckScreen] =
    useState(false)
  const openPreviousTaskCheckScreen = (): void => {
    setIsOpenPreviousTaskCheckScreen(true)
  }
  const closePreviousTaskCheckScreen = (): void => {
    setIsOpenPreviousTaskCheckScreen(false)
  }
  /**
   * 前のタスクボタンを押した際のハンドラ。
   * 未入力の点検項目がなければ仮登録とページ遷移を行い、存在した場合は確認用のdialogを開く。
   *
   */
  const handlePreviousTask = (): void => {
    if (isExistsUnenteredInput()) {
      openPreviousTaskCheckScreen()
    } else {
      transitionToDifferentTask({ relativePageNumber: TaskPage.PreviousTask })
    }
  }

  const [isOpenGroupListCheckScreen, setIsOpenGroupListCheckScreen] =
    useState(false)
  const openGroupListCheckScreen = (): void => {
    setIsOpenGroupListCheckScreen(true)
  }
  const closeGroupListCheckScreen = (): void => {
    setIsOpenGroupListCheckScreen(false)
  }
  /**
   * グループ一覧タスクボタンを押した際のハンドラ。
   * 未入力の点検項目がなければ仮登録とページ遷移を行い、存在した場合は確認用のdialogを開く。
   *
   */
  const handleGroupList = (): void => {
    if (firstGroupId == null || lastGroupId == null || typeName === '') {
      throw new Error('first / lastGroupId or typeName is null.')
    }
    if (isExistsUnenteredInput()) {
      openGroupListCheckScreen()
    } else {
      transitionToGroupList()
    }
  }
  // 計器一覧ページへの遷移を行う
  const transitionToGroupList = (): void => {
    if (typeName === '') {
      throw new Error('typeName is null.')
    } else {
      updateValue()
        .then(() => {
          setOpenSuccess(true)
        })
        .catch((error) => {
          console.log(error)
          setOpenFail(true)
        })
      navigate(`/modification/inspectionInput/${typeName}`)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          alignItems: 'center'
        }}
      >
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h5">過去結果修正</Typography>
          </Grid>
        </Grid>
        <TaskCardsWithSkeleton
          machineTaskList={machineTaskList}
          placeName={placeName}
          updateMachineTaskList={updateMachineTaskList}
          machineNotes={machineNotes}
          setMachineNotes={setMachineNotes}
        />
        <FooterButtons
          previousTaskButtonHandler={handlePreviousTask}
          nextTaskButtonHandler={handleNextTask}
          groupListButtonHandler={handleGroupList}
        />
        <CommonDialog
          message="未入力の点検項目がありますが、次のタスクページを開きますか？"
          isOpen={isOpenNextTaskCheckScreen}
          doYes={() => {
            closeNextTaskCheckScreen()
            transitionToDifferentTask({
              relativePageNumber: TaskPage.NextTask
            })
          }}
          doNo={closeNextTaskCheckScreen}
        />
        <CommonDialog
          message="未入力の点検項目がありますが、前のタスクページを開きますか？"
          isOpen={isOpenPreviousTaskCheckScreen}
          doYes={() => {
            closePreviousTaskCheckScreen()
            transitionToDifferentTask({
              relativePageNumber: TaskPage.PreviousTask
            })
          }}
          doNo={closePreviousTaskCheckScreen}
        />
        <CommonDialog
          message="未入力の点検項目がありますが、グループ一覧ページを開きますか？"
          isOpen={isOpenGroupListCheckScreen}
          doYes={() => {
            closeGroupListCheckScreen()
            transitionToGroupList()
          }}
          doNo={closeGroupListCheckScreen}
        />
        <UpdateCogniteSnackBar />
      </Box>
    </Container>
  )
}
