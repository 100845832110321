import React, { forwardRef } from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

export interface FailSnackBarProps {
  message: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: (event?: React.SyntheticEvent, reason?: string) => void
  duration?: number
  style?: React.CSSProperties
}

export const FailSnackBar: React.FC<FailSnackBarProps> = (props) => {
  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.duration ?? 5000}
      onClose={() => props.setOpen(false)}
      style={props.style}
      sx={{ maxWidth: '100%' }}
    >
      <Alert
        onClose={props.handleClose}
        severity="error"
        sx={{
          width: '100%',
          whiteSpace: 'pre-line',
          wordWrap: 'break-word'
        }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}
