import { MachinesSchema, getMachines } from 'api/backendApi'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useAuthUser } from './useAuthUser'
import { useGroupIdParam } from './useUrlParam'

export interface UseMachineListReturn {
  isLoading: boolean
  error: unknown
  machineList: MachinesSchema[]
}

export const useMachineList = (): UseMachineListReturn => {
  // contextから以前使用していたidTokenを取得
  // 非同期的に新しいidTokenも取得し、idTokenにsetする
  // これにより、idToken取得を待たずにAPIコールが可能になる
  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])

  const { groupId } = useGroupIdParam()

  const fetchMachineList = async (
    groupId: number
  ): Promise<MachinesSchema[]> => {
    const response = await getMachines({
      groupId,
      idToken
    }).catch((error) => {
      console.log(error)
      throw new Error('getMachines API is failed.')
    })

    return response
  }

  const { isLoading, error, data } = useQuery(
    `machineList-${groupId}`,
    async () => await fetchMachineList(groupId),
    {
      // tracked を指定
      notifyOnChangeProps: 'tracked'
    }
  )

  return {
    isLoading,
    error,
    machineList: data != null ? data : []
  }
}
