import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { commonConst } from 'consts/commonConst'

const clientId = commonConst.AZURE_APP_ID
const tenantId = commonConst.AZURE_TENANT_ID
const cluster = commonConst.CLUSTER
export const baseUrl = `https://${cluster}.cognitedata.com`
export const scopes = [
  `${baseUrl}/DATA.VIEW`,
  `${baseUrl}/DATA.CHANGE`,
  `${baseUrl}/IDENTITY`
]

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`
  }
}

if (clientId === '' || tenantId === '') {
  throw new Error(
    'specify REACT_APP_AZURE_APP_ID and REACT_APP_AZURE_TENANT_ID in your environment'
  )
}

interface AccessTokenJson {
  homeAccountId: string
  credentialType: string
  secret: string
  cachedAt: string
  expiresOn: string
  extendedExpiresOn: string
  environment: string
  clientId: string
  realm: string
  target: string
  tokenType: string
}
let accessTokenValue: AccessTokenJson = {
  homeAccountId: '',
  credentialType: '',
  secret: '',
  cachedAt: '',
  expiresOn: '',
  extendedExpiresOn: '',
  environment: '',
  clientId: '',
  realm: '',
  target: '',
  tokenType: ''
}

export const pca = new PublicClientApplication(configuration)
// 引数がstring型であることをisStringの呼び出し元に伝える。
const isString = (test: unknown): test is string => {
  return typeof test === 'string'
}

export const getToken = async (): Promise<string> => {
  // sessionStorageでループを回し、accessTokenを取得する
  for (let i = 0; i < sessionStorage.length; i++) {
    const sessionStorageKey = sessionStorage.key(i)
    if (
      !isString(sessionStorageKey) ||
      sessionStorageKey.match(/accesstoken/) == null
    ) {
      continue
    }
    const sessionStorageValue = sessionStorage.getItem(sessionStorageKey)
    if (sessionStorageValue == null) {
      throw new Error('sessionStorageValue is null')
    }
    accessTokenValue = JSON.parse(sessionStorageValue)
    break
  }

  if (accessTokenValue.homeAccountId === '') {
    throw new Error('No cached Access Token found')
  }

  const account = pca.getAccountByHomeId(accessTokenValue.homeAccountId)
  if (account == null) {
    throw new Error('no user found')
  }
  const token = await pca.acquireTokenSilent({
    account,
    scopes
  })
  return token.accessToken
}
