import React, { StrictMode } from 'react'
import {
  createStyles,
  makeStyles,
  createTheme,
  ThemeProvider,
  Theme
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
// msal
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react'
import { QueryClientProvider, QueryClient } from 'react-query'

import './App.css'
import Main from './components/Main/Main'
import Login from './components/Pages/Login'
import { pca, scopes } from './auth/auth'

import { InspectionTypesProvider } from 'contexts/InspectionTypesContext'
import { IdTokenProvider } from 'contexts/IdTokenContext'
import { OpenSnackBarProvider } from 'contexts/OpenSnackBarContext'
import { GroupListProvider } from 'contexts/groupListContext'
import { ModifyStartEventProvider } from 'contexts/ModifyStartEvent'

const theme: Theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000'
    }
  }
})

const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
)

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1
    }
  }
})

// Component
const AppProvider = (): JSX.Element => (
  // TODO contextの共有範囲の最小化
  <StrictMode>
    <QueryClientProvider client={client}>
      <MsalProvider instance={pca}>
        <ModifyStartEventProvider>
          <GroupListProvider>
            <IdTokenProvider>
              <InspectionTypesProvider>
                <ThemeProvider theme={theme}>
                  <OpenSnackBarProvider>
                    <App />
                  </OpenSnackBarProvider>
                </ThemeProvider>
              </InspectionTypesProvider>
            </IdTokenProvider>
          </GroupListProvider>
        </ModifyStartEventProvider>
      </MsalProvider>
    </QueryClientProvider>
  </StrictMode>
)
export default AppProvider

const App: React.FC = () => {
  const { instance } = useMsal()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AuthenticatedTemplate>
        <Main />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login instance={instance} scopes={scopes} />
      </UnauthenticatedTemplate>
    </div>
  )
}
