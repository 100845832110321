import {
  ItemsSchema,
  getTaskList,
  MachinesSchema,
  Alertflag
} from 'api/backendApi'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import React, { useCallback, useEffect, useState } from 'react'
import { useAuthUser } from './useAuthUser'

export interface UpdateMachineTaskListProps {
  machineIndex: number
  taskIndex: number
  newValue: number | null | undefined
}

export interface UseMachineTaskListProps {
  machineList: MachinesSchema[]
}

export interface UseMachineTaskListReturn {
  machineTaskList: MachineTask[]
  setMachineTaskList: React.Dispatch<React.SetStateAction<MachineTask[]>>
  updateMachineTaskList: (props: UpdateMachineTaskListProps) => void
}

export interface MachineTask {
  machineName: string
  machineMemo: string | null
  tasks: ItemsSchema[]
}

export const useMachineTaskList = (
  props: UseMachineTaskListProps
): UseMachineTaskListReturn => {
  /**
   * taskについて、alert状態かの判断を行う
   *
   * @param {ItemsSchema} task
   * @return {boolean}
   */
  const isAlert = (task: ItemsSchema): boolean => {
    // 値が最小値以下の場合、trueを返す
    if (
      task.minimumValue != null &&
      task.value != null &&
      task.value < task.minimumValue
    ) {
      return true
    }
    // 値が最大値以上の場合、trueを返す
    if (
      task.maximumValue != null &&
      task.value != null &&
      task.value > task.maximumValue
    ) {
      return true
    }
    // 選択肢がevaluationflagに該当する場合、trueを返す
    if (
      task.eventflag === 1 &&
      task.value != null &&
      task.evaluationflag != null &&
      // evaluationflagは何番目の選択肢がNGかを意味する。valueは0から始まるため、+1する
      task.value + 1 === task.evaluationflag
    ) {
      return true
    }
    return false
  }

  const setAlertflag = useCallback((task: ItemsSchema): ItemsSchema => {
    if (isAlert(task)) {
      task.alertflag = Alertflag.On
    } else {
      task.alertflag = Alertflag.Off
    }
    return task
  }, [])

  // contextから以前使用していたidTokenを取得
  // 非同期的に新しいidTokenも取得し、idTokenにsetする
  // これにより、idToken取得を待たずにAPIコールが可能になる
  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])

  // machineTaskListの生成を行う
  const [machineTaskList, setMachineTaskList] = useState<MachineTask[]>([])
  useEffect(() => {
    if (props.machineList.length === 0 || idToken === '') {
      return
    }

    const fetchMachineTaskList = async (): Promise<void> => {
      const taskLists = await Promise.all(
        props.machineList.map(async (machine) => {
          return await getTaskList({
            machineId: machine.machineId,
            idToken
          })
        })
      ).catch((error) => {
        console.log(error)
        throw new Error('getTaskList is failed')
      })
      const newMachineTaskList = taskLists.map((taskList, taskListIndex) => {
        return {
          machineName: props.machineList[taskListIndex].machineName,
          machineMemo: props.machineList[taskListIndex].machineMemo,
          // taskList取得時にはalertflagがセットされていないため、全てのタスクに対してalert判定&flagセットを行う
          tasks: taskList.map((task) => {
            const alertflagSetTask: ItemsSchema = setAlertflag(task)
            return alertflagSetTask
          })
        }
      })
      setMachineTaskList(newMachineTaskList)
    }
    fetchMachineTaskList().catch((error) => {
      console.log(error)
      throw new Error('fetchMachineTaskList is failed.')
    })

    return () => {
      setMachineTaskList([])
    }
  }, [idToken, props.machineList, setAlertflag])

  /**
   * machineIndex, taskIndexを元にmachineTaskListをupdateする関数
   *
   * @param {UpdateMachineTaskListProps} props
   */
  const updateMachineTaskList = (props: UpdateMachineTaskListProps): void => {
    if (machineTaskList.length === 0) {
      return
    }

    const newMachineTaskList = machineTaskList.map((machine, machineIndex) => {
      if (machineIndex !== props.machineIndex) {
        return machine
      }
      const newMachine: MachineTask = {
        machineName: machine.machineName,
        machineMemo: machine.machineMemo != null ? machine.machineMemo : '',
        tasks: machine.tasks.map((task, taskIndex) => {
          if (taskIndex !== props.taskIndex) {
            return task
          }
          const valueSetTask: ItemsSchema = {
            ...task,
            value: props.newValue
          }
          const alertflagSetTask: ItemsSchema = setAlertflag(valueSetTask)
          return alertflagSetTask
        })
      }
      return newMachine
    })
    setMachineTaskList(newMachineTaskList)
  }

  return {
    machineTaskList,
    setMachineTaskList,
    updateMachineTaskList
  }
}
