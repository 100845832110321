import { envConst } from 'consts/envConst'
import { retryAxios } from './retry'

const INSPECTIONS_TYPES_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/inspectionType`
const GROUP_API_URL = `${envConst.BACKEND_API_ROOT_URL}/group`
const GROUP_TIMESTAMP_API_URL = `${envConst.BACKEND_API_ROOT_URL}/group/timestamp`
const MACHINES_LIST_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/machines`
const COGNITE_REGISTER_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/machines/result`
const TIMESTAMP_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/group/timestamp`
const TASK_LIST_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/task/items`
const NOTE_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/task/note`
// const AWS_REGISTER_API_URL: string =
//   envConst.BACKEND_API_ROOT_URL + '/task/registration'
const AWS_REGISTER_TIMESERIES_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/task/registration/timeseries`
const AWS_REGISTER_NOTE_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/task/registration/note`
const TIMESERIES_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/task/timeseries`
const REGISTRATIONFLAG_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/registrationflag`
const SHEET_FORMAT_API_URL: string = `${envConst.BACKEND_API_ROOT_URL}/sheet/format`

// backendAPIにおけるtypeId schemaの型定義
export interface TypeIdSchema {
  type: string
  versionId: number
  registrationflag: number
}

export enum RegistrationflagType {
  NotRegistered,
  Registered
}

export interface GroupSchema {
  groupId: number
  groupName: string
  placeName: string
  timestamp?: string
}

// backendAPIにおけるMachines schemaの型定義
export interface MachinesSchema {
  machineId: number
  machineName: string
  machineMemo: string | null
  assetExternalId: string
  assetId: number
}

// backendAPIにおけるItems schemaの型定義
export interface ItemsSchema {
  taskItemId: number
  taskName: string
  taskMemo: string | null
  cogniteExternalId: string
  assetId: number
  eventflag: number
  ioMode: number
  value?: number | null
  unit?: string
  maximumValue?: number
  minimumValue?: number
  zeroDisplay?: string
  oneDisplay?: string
  twoDisplay?: string
  threeDisplay?: string
  fourDisplay?: string
  fiveDisplay?: string
  sixDisplay?: string
  sevenDisplay?: string
  eightDisplay?: string
  nineDisplay?: string
  threshold?: string
  evaluationflag?: number
  // 元々定義されているitemsSchemaには存在せず、frontendでのみ追加
  alertflag?: number
}

export interface TypeSchema {
  type: string
}

export enum Eventflag {
  InputNumber,
  Select
}

export enum IoMode {
  Output,
  Input
}

export enum Evaluationflag {
  None = 0,
  OneIsNg = 1 << 0,
  TwoIsNg = 1 << 1,
  ThreeIsNg = 1 << 2,
  FourIsNg = 1 << 3,
  FiveIsNg = 1 << 4,
  SixIsNg = 1 << 5,
  SevenIsNg = 1 << 6,
  EightIsNg = 1 << 7,
  NineIsNg = 1 << 8
}

export interface TimeseriesInspectionDataSchema {
  taskItemId: number
  timestamp: number
  resultValue: number
  alerflag: number
}

export enum Alertflag {
  Off,
  On
}

export interface NoteInspectionData {
  machineId: number
  note: string
}

// backendAPIにおけるAssetId schemaの型定義
// export interface AssetIdSchema {
//   assetId: number
// }

// backendAPIにおけるError schemaの型定義
export interface ErrorSchema {
  statusCode: number
}

// backendAPIにおけるUnauthorized schemaの型定義
export interface UnauthorizedSchema {
  statusCode: number
}

export interface GetInspectionTypesProps {
  idToken: string
}
/**
 * 種別IDを一括取得する関数
 *
 * @param {GetInspectionTypesProps} props
 * @return {Promise<TypeIdSchema[]>}
 */
export const getInspectionTypes = async (
  props: GetInspectionTypesProps
): Promise<TypeIdSchema[]> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${INSPECTIONS_TYPES_API_URL}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('getInspectionTypes API is failed.')
  })

  if (response.status !== 200) {
    throw new Error(
      `getInspectionTypes is failed. status code is ${response.status}`
    )
  }

  return response.data
}

export interface GetInspectionTypeNameProps {
  idToken: string
  versionId: string
}
/**
 * バージョンIDから種別名を取得する関数
 *
 * @param {GetInspectionTypeNameProps} props
 * @return {Promise<TypeSchema[]>}
 */
export const getInspectionTypeName = async (
  props: GetInspectionTypeNameProps
): Promise<TypeSchema[]> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${INSPECTIONS_TYPES_API_URL}/${props.versionId}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('getInspectionTypes API is failed.')
  })

  if (response.status !== 200) {
    throw new Error(
      `getInspectionTypes is failed. status code is ${response.status}`
    )
  }

  return response.data
}

export interface enableRegistrationflagProps {
  versionId: number
  idToken: string
}

/**
 * 点検中フラグ(正式名所未登録フラグ)をtrueにする関数
 *
 * @param {enableRegistrationflagProps} props
 */
export const enableRegistrationflag = async (
  props: enableRegistrationflagProps
): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${REGISTRATIONFLAG_API_URL}/${props.versionId}`,
    method: 'post',
    data: {},
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('enableRegistrationflag API is failed')
  })

  if (response.status !== 200) {
    throw new Error(
      `enableRegistrationflag is failed. status code is ${response.status}`
    )
  }
}

export interface disableRegistrationflagProps {
  versionId: number
  idToken: string
}

/**
 * 点検中フラグ(正式名所未登録フラグ)をfalseにする
 *
 * @param {disableRegistrationflagProps} props
 */
export const disableRegistrationflag = async (
  props: disableRegistrationflagProps
): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${REGISTRATIONFLAG_API_URL}/${props.versionId}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('disableRegistrationflag API is failed')
  })

  if (response.status !== 200) {
    throw new Error(
      `disableRegistrationflag is failed. status code is ${response.status}`
    )
  }
}

export interface GetGroupsProps {
  versionId: number
  idToken: string
}

/**
 * グループ一覧を取得する関数
 *
 * @param {GetGroupsProps} props
 * @return {Promise<GroupSchema[]>}
 */
export const getGroups = async (
  props: GetGroupsProps
): Promise<GroupSchema[]> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${GROUP_API_URL}/${props.versionId}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('getGroups API is failed.')
  })

  if (response.status !== 200) {
    throw new Error(`getGroups is failed. status code is ${response.status}`)
  }

  return response.data
}

export interface GetMachinesProps {
  groupId: number
  idToken: string
}

/**
 * typeIdに基づいて計器一覧を取得する関数
 *
 * @param {GetMachinesProps} props
 * @return {Promise<MachinesSchema[]>}
 */
export const getMachines = async (
  props: GetMachinesProps
): Promise<MachinesSchema[]> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${MACHINES_LIST_API_URL}/${props.groupId}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('getMachines API is failed.')
  })

  if (response.status !== 200) {
    throw new Error(`getMachines is failed. status code is ${response.status}`)
  }
  return response.data
}

export interface RegisterInspectionTimestampProps {
  groupId: number
  idToken: string
}

/**
 * 仮登録時刻を更新するAPI
 *
 * @param {RegisterInspectionTimestampProps} props
 */
export const registerInspectionTimestamp = async (
  props: RegisterInspectionTimestampProps
): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${GROUP_TIMESTAMP_API_URL}/${props.groupId}`,
    method: 'post',
    data: {},
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  })

  if (response.status !== 200) {
    throw new Error(
      `registerInspectionTimestamp is failed. status code is ${response.status}`
    )
  }
}

export interface DeleteTimestampProps {
  versionId: number
  idToken: string
}

/**
 * 仮登録済みの最終点検日時を全て削除する関数
 *
 * @param {DeleteTimestampProps} props
 */
export const deleteTimestamp = async (
  props: DeleteTimestampProps
): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${TIMESTAMP_API_URL}/${props.versionId}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('deleteTimestamp API is failed')
  })
  // TODO 401 not authorizedの場合、権限が無いページに遷移する
  if (response.status !== 200) {
    throw new Error(
      `deleteTimestamp is failed. status code is ${response.status}`
    )
  }
}

export interface RegisterInspectionResultToCogniteProps {
  versionId: number
  idToken: string
}

/**
 * 点検記録、備考記録に登録された内容をcogniteに一括登録する
 *
 * @param {RegisterInspectionResultToCogniteProps} props
 */
export const registerInspectionResultToCognite = async (
  props: RegisterInspectionResultToCogniteProps
): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${COGNITE_REGISTER_API_URL}/${props.versionId}`,
    method: 'post',
    data: {},
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('registerInspectionResultToCognite API is failed')
  })
  // TODO 401 not authorizedの場合、権限が無いページに遷移する
  if (response.status !== 200) {
    throw new Error(
      `registerInspectionResultToCognite is failed. status code is ${response.status}`
    )
  }
}

export interface GetTaskListProps {
  machineId: number
  idToken: string
}

/**
 * 点検順序IDに基づいて点検項目一覧を取得する関数
 *
 * @param {GetTaskListProps} props
 * @return {Promise<ItemsSchema[]>}
 */
export const getTaskList = async (
  props: GetTaskListProps
): Promise<ItemsSchema[]> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${TASK_LIST_API_URL}/${props.machineId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('getTaskList API is failed')
  })
  // TODO 401 not authorizedの場合、権限が無いページに遷移する
  if (response.status !== 200) {
    throw new Error(`getTaskList is failed. status code is ${response.status}`)
  }
  const taskList: ItemsSchema[] = response.data

  return taskList
}

export interface GetRegiisterdNoteProps {
  idToken: string
  machineId: number
}
/**
 * 仮登録された備考を取得する関数
 *
 * @param {GetRegiisterdNoteProps} props
 * @return {Promise<string>}
 */
export const getRegisterdNote = async (
  props: GetRegiisterdNoteProps
): Promise<string> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${NOTE_API_URL}/${props.machineId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('getRegisterdNote API is failed')
  })
  // TODO 401 not authorizedの場合、権限が無いページに遷移する
  if (response.status !== 200) {
    throw new Error(
      `getRegisterdNote is failed. status code is ${response.status}`
    )
  }

  return response.data
}

export interface RegisterTimeseriesResultProps {
  taskItemId: number
  resultValue: number | null
  alertflag: number
  idToken: string
}

/**
 * timeseriesの結果を登録する関数
 *
 * @param {RegisterTimeseriesResultProps} props
 */
export const registerTimeseriesResult = async (
  props: RegisterTimeseriesResultProps
): Promise<void> => {
  const getMysqlDatetime = (): string => {
    const ymd = new Date().toLocaleDateString().replace(/\//g, '-')
    const time = new Date().toLocaleTimeString()
    return ymd + ' ' + time
  }

  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const postData = {
    timestamp: getMysqlDatetime(),
    resultValue: props.resultValue,
    alertflag: props.alertflag
  }

  const response = await retryAxios({
    url: `${AWS_REGISTER_TIMESERIES_API_URL}/${props.taskItemId}`,
    method: 'POST',
    data: postData,
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  })

  if (response.status !== 200) {
    throw new Error(
      `registerTimeseriesResult is failed. status code is ${response.status}`
    )
  }
}

export interface RegisterNoteResultProps {
  machineId: number
  note: string
  idToken: string
}

/**
 * 備考を登録する関数
 *
 * @param {RegisterNoteResultProps} props
 */
export const registerNoteResult = async (
  props: RegisterNoteResultProps
): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const postData = {
    note: props.note
  }

  const response = await retryAxios({
    url: `${AWS_REGISTER_NOTE_API_URL}/${props.machineId}`,
    method: 'POST',
    data: postData,
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  })

  if (response.status !== 200) {
    throw new Error(
      `registerNoteResult is failed. status code is ${response.status}`
    )
  }
}

export interface DeleteTimeseriesProps {
  versionId: number
  idToken: string
}

/**
 * 仮登録済みの最終点検日時を全て削除する関数
 *
 * @param {DeleteTimeseriesProps} props
 */
export const deleteTimeseries = async (
  props: DeleteTimeseriesProps
): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${TIMESERIES_API_URL}/${props.versionId}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('deleteTimeseries API is failed')
  })
  // TODO 401 not authorizedの場合、権限が無いページに遷移する
  if (response.status !== 200) {
    throw new Error(
      `deleteTimeseries is failed. status code is ${response.status}`
    )
  }
}

export interface DeleteNotesProps {
  versionId: number
  idToken: string
}

/**
 * 仮登録済みの備考を全て削除する関数
 *
 * @param {DeleteNotesProps} props
 */
export const deleteNotes = async (props: DeleteNotesProps): Promise<void> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${NOTE_API_URL}/${props.versionId}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('deleteNotes API is failed')
  })
  // TODO 401 not authorizedの場合、権限が無いページに遷移する
  if (response.status !== 200) {
    throw new Error(`deleteNotes is failed. status code is ${response.status}`)
  }
}

export interface taskType {
  taskName: string
  unit: string
  controlValue: string
  result: string
  notes: string
  externalId: string
  eventflag: number
  zeroDisplay?: string
  oneDisplay?: string
  twoDisplay?: string
}

export interface machineType {
  machineName: string
  machineId: string
  taskOrderId?: string
  notes: string
  task: taskType[]
}

export interface placeType {
  placeName: string
  machine: machineType[]
}

export interface GetSheetFormatProps {
  versionId: string
  idToken: string
}

/**
 * 点検結果シートのフォーマットを取得する関数
 *
 * @param {GetSheetFormatProps} props
 * @return {Promise<placeType[]>}
 */
export const getSheetFormat = async (
  props: GetSheetFormatProps
): Promise<placeType[]> => {
  if (props.idToken === '') {
    throw new Error('idToken is null')
  }

  const response = await retryAxios({
    url: `${SHEET_FORMAT_API_URL}/${props.versionId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${props.idToken}`
    }
  }).catch((error) => {
    console.log(error)
    throw new Error('getSheetFormat API is failed')
  })
  // TODO 401 not authorizedの場合、権限が無いページに遷移する
  if (response.status !== 200) {
    throw new Error(
      `getSheetFormat is failed. status code is ${response.status}`
    )
  }

  return response.data
}
