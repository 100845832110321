import { useState, useEffect } from 'react'
import { useGroupList } from './useGroupList'

export interface useFirstAndLastGroupIdReturn {
  firstGroupId: number | undefined
  lastGroupId: number | undefined
}

export const useFirstAndLastGroupId = (): useFirstAndLastGroupIdReturn => {
  const { groupList } = useGroupList()
  const [firstGroupId, setFirstGroupId] = useState<number>()
  const [lastGroupId, setLastGroupId] = useState<number>()
  useEffect(() => {
    if (
      groupList.length === 0 ||
      groupList[0] == null ||
      groupList[0].groupId == null ||
      Number.isNaN(groupList[0].groupId)
    ) {
      return
    }

    setFirstGroupId(groupList[0].groupId)
    setLastGroupId(groupList.slice(-1)[0].groupId)
  }, [groupList])

  return {
    firstGroupId,
    lastGroupId
  }
}
