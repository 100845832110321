import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import { Button, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CogniteEvent } from '@cognite/sdk'
import { useInspectionEvents } from 'hooks/useInspectionEvents'
import { useSetModifyStartEvent } from 'contexts/ModifyStartEvent'
import { getInspectionTypeName } from 'api/backendApi'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import { useAuthUser } from 'hooks/useAuthUser'
import { InspectionEventCard } from 'components/Molecules/InspectionEventCard'

export const InspectionResultsList: React.FC = () => {
  const navigate = useNavigate()
  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])
  // Cogniteに登録されている点検開始イベントを保存する配列の管理
  const { inspectionEvents, loadMore } = useInspectionEvents({
    maxEvents: 100
  })
  const [isModificationClicked, setIsmodificationClicked] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const eventsPerPage = 20
  // 3ページごとにloadMoreを呼び出す
  const loadMoreThreshold = 3

  useEffect(() => {
    if (currentPage % loadMoreThreshold === 0) {
      void loadMore()
    }
  }, [currentPage, loadMore])

  const handleNextPage = (): void => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = (): void => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  const startIndex = (currentPage - 1) * eventsPerPage
  const currentEvents = inspectionEvents.slice(
    startIndex,
    startIndex + eventsPerPage
  )

  const setModifyStartEvent = useSetModifyStartEvent()
  // 修正ボタンクリック時のハンドラ
  const onClickModification = async (
    startEvent: CogniteEvent
  ): Promise<void> => {
    if (startEvent?.metadata?.versionId == null) {
      throw new Error('versionId is not defined in InspectionStartEvent.')
    }
    setIsmodificationClicked(true)
    setModifyStartEvent(startEvent)
    const typeName = await getInspectionTypeName({
      idToken,
      versionId: startEvent.metadata.versionId
    }).catch((error) => {
      setIsmodificationClicked(false)
      console.log(error)
      throw new Error('getInspectiontypeName is failed')
    })
    setIsmodificationClicked(false)

    const typeString = typeName[0].type
    navigate(`/modification/inspectionInput/${typeString}`)
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          alignItems: 'center'
        }}
      >
        <h2> 点検結果一覧 </h2>
        <List>
          {currentEvents.map((startEvent: CogniteEvent) => {
            if (typeof startEvent.endTime !== 'undefined') {
              return (
                <InspectionEventCard
                  startEvent={startEvent}
                  onClickModification={onClickModification}
                  isModificationClicked={isModificationClicked}
                  key={startEvent.id}
                />
              )
            } else {
              return <></>
            }
          })}
        </List>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              variant="contained"
            >
              前へ
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleNextPage}
              disabled={currentEvents.length < eventsPerPage}
              variant="contained"
            >
              次へ
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}
