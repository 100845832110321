import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Home } from 'components/Pages/Home'
import { TaskList } from 'components/Pages/TaskList'
import { AppBarComponent } from 'components/Molecules/AppBar'
import { InspectionResult } from 'components/Pages/InspectionResult'
import { InspectionResultsList } from 'components/Pages/InspectionResultsList'
import GroupList from 'components/Pages/GroupList'
import ModificationGroupList from 'components/Pages/ModificationGroupList'
import { ModificationTaskList } from 'components/Pages/ModificationTaskList'

const Main: React.FC = () => {
  return (
    <div>
      <BrowserRouter>
        <AppBarComponent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/inspectionResultsList"
            element={<InspectionResultsList />}
          />
          <Route
            path="/inspectionResultsList/:eventId"
            element={<InspectionResult />}
          />
          <Route path="/inspectionInput/:typeName" element={<GroupList />} />
          <Route
            path="/inspectionInput/:typeName/:groupId"
            element={<TaskList />}
          />
          <Route
            path="/modification/inspectionInput/:typeName"
            element={<ModificationGroupList />}
          />
          <Route
            path="/modification/inspectionInput/:typeName/:groupId"
            element={<ModificationTaskList />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default Main
