import { useGroupList } from 'contexts/groupListContext'

export interface usePlaceNameProps {
  groupId: number
}

export interface usePlaceNameReturn {
  placeName: string
}

export const usePlaceName = (props: usePlaceNameProps): usePlaceNameReturn => {
  const groupList = useGroupList()
  if (groupList.length === 0) {
    return { placeName: '' }
  }

  let placeName = ''
  groupList.forEach((group) => {
    if (group.groupId === props.groupId) {
      placeName = group.groupName
    }
  })

  return { placeName }
}
