import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useTypeNameParam } from 'hooks/useUrlParam'
import { useAuthUser } from 'hooks/useAuthUser'
import { registerInspectionResultToCognite } from 'api/backendApi'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import CommonDialog from 'components/Molecules/CommonDialog'
import { TempRegistrationSnackBar } from 'components/Molecules/TempRegistrationSnackBar'
import { CogniteRegistrationSnackBar } from 'components/Molecules/CogniteRegistrationSnackBar'
import { usePlaceGroupList } from 'hooks/usePlaceGroupList'
import { useVersionId } from 'hooks/useVersionId'
import { Skeleton } from '@mui/material'

const theme = createTheme()

/**
 * グループ名一覧を表示するコンポーネント
 *
 * @return {JSX.Element}
 */
const GroupList: React.FC = () => {
  // contextから以前使用していたidTokenを取得
  // 非同期的に新しいidTokenも取得し、idTokenにsetする
  // これにより、idToken取得を待たずにAPIコールが可能になる
  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])

  const navigate = useNavigate()
  // URLから種別に対応したパラメータを取得
  const { typeName } = useTypeNameParam()
  const handleToTaskListPage = (props: number): void => {
    navigate(`/inspectionInput/${typeName}/${props}`)
  }
  const { versionId } = useVersionId()
  const { placeList, placeGroupList } = usePlaceGroupList()

  // 登録確認ダイアログ表示に用いるstate
  const [isRegisterConfirmation, setIsRegisterConfirmation] = useState(false)
  const closeRegisterConfirmationDialog = (): void => {
    setIsRegisterConfirmation(false)
  }
  // cogniteへの登録が正常終了した場合にsnackbarによる通知を行うための変数群
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFail, setOpenFail] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)
  // ダイアログ上で登録確認ボタンを押した際のコールバック
  const registerInspectionResult = (): void => {
    setIsRegisterConfirmation(false)
    setIsRegistering(true)
    if (versionId == null) {
      throw new Error('versionId is invalid')
    }
    if (idToken === '') {
      throw new Error('idToken is invalid')
    }

    registerInspectionResultToCognite({
      versionId,
      idToken
    })
      .then(() => {
        setOpenSuccess(true)
        navigate('/')
      })
      .catch((error) => {
        console.log(error)
        setOpenFail(true)
        throw new Error('Data registration to Cognite is failed')
      })
      .finally(() => {
        setIsRegistering(false)
      })
  }

  interface UpdatedTimestampTextProps {
    timestamp?: string
    groupId?: number
  }
  /**
   * 仮登録時刻を表示するテキストコンポーネント
   *
   * @param {UpdatedTimestampTextProps} props
   * @return {JSX.Element}
   */
  const UpdatedTimestampText: React.FC<UpdatedTimestampTextProps> = (props) => {
    if (
      props.timestamp != null &&
      props.timestamp !== '' &&
      props.groupId != null
    ) {
      return (
        <Grid container direction="column" spacing={0}>
          <Grid item>
            <ListItemText
              primary={`${props.timestamp.split(' ')[0]}`}
              key={props.groupId}
            />
          </Grid>
          <Grid item>
            <ListItemText
              primary={`${props.timestamp.split(' ')[1]}`}
              key={props.groupId}
            />
          </Grid>
        </Grid>
      )
    } else {
      return <div></div>
    }
  }

  interface GroupCardProps {
    groupId: number
    groupName: string
    timestamp?: string
  }
  /**
   * 点検項目ページに遷移するグルーポ名が書かれたカードコンポーネント
   *
   * @param {GroupCardProps} props
   * @return {JSX.Element}
   */
  const GroupCard: React.FC<GroupCardProps> = (props) => {
    return (
      <Paper style={{ margin: '16px 0px' }} key={props.groupId}>
        <ListItem key={props.groupId}>
          <ListItemButton
            onClick={() => handleToTaskListPage(props.groupId)}
            key={props.groupId}
          >
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={7}>
                <ListItemText
                  primary={`${props.groupName}`}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}
                  key={props.groupId}
                />
              </Grid>
              <Grid item xs={5}>
                <UpdatedTimestampText
                  timestamp={props.timestamp}
                  groupId={props.groupId}
                />
              </Grid>
            </Grid>
          </ListItemButton>
        </ListItem>
      </Paper>
    )
  }

  /**
   * 場所名、グループカード、skeletonを表示するコンポーネント
   *
   * @return {JSX.Element}
   */
  const GroupButtonsWithSkeleton: React.FC = () => {
    if (placeList.length > 0) {
      return (
        <List>
          {placeList.map((place) => (
            <li key={place}>
              <ul key={place}>
                <ListSubheader
                  color="primary"
                  sx={{
                    fontSize: 'x-large',
                    backgroundColor: '#f9f9ff'
                  }}
                  key={place}
                >{`${place}`}</ListSubheader>
                {placeGroupList[place].map((group) => (
                  <GroupCard
                    groupId={group.groupId}
                    groupName={group.groupName}
                    timestamp={group.timestamp}
                  />
                ))}
              </ul>
            </li>
          ))}
        </List>
      )
    } else {
      // ローディング中の場合、skeletonを表示させる
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={300}
              height={45}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={300}
              height={80}
              sx={{ margin: 2 }}
            />
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            alignItems: 'center'
          }}
        >
          <GroupButtonsWithSkeleton />
          <Grid container justifyContent="center">
            <Grid item>
              <TempRegistrationSnackBar />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsRegisterConfirmation(true)}
                disabled={isRegistering}
                sx={{ width: 220 }}
              >
                本登録してHOMEに戻る
              </Button>
            </Grid>
            <Grid item>
              <CogniteRegistrationSnackBar
                openSuccess={openSuccess}
                setOpenSuccess={setOpenSuccess}
                openFail={openFail}
                setOpenFail={setOpenFail}
              />
            </Grid>
          </Grid>
          <CommonDialog
            message="点検結果をcogniteに登録しますか？"
            isOpen={isRegisterConfirmation}
            doYes={registerInspectionResult}
            doNo={closeRegisterConfirmationDialog}
          />
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default GroupList
