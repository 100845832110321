import React, { SyntheticEvent } from 'react'
import {
  useOpenSuccess,
  useSetOpenSuccess,
  useOpenFail,
  useSetOpenFail
} from 'contexts/OpenSnackBarContext'
import { SuccessSnackBar } from 'components/Atoms/SuccessSnackBar'
import { FailSnackBar } from 'components/Atoms/FailSnackBar'

interface TempRegistrationSnackBarProps {
  errorMessage?: string
  successMessage?: string
}

export const TempRegistrationSnackBar: React.FC<
  TempRegistrationSnackBarProps
> = (props) => {
  // cogniteへの登録が成功/失敗した場合にsnackbarによる通知を行うための変数群
  const openSuccess = useOpenSuccess()
  const setOpenSuccess = useSetOpenSuccess()
  const openFail = useOpenFail()
  const setOpenFail = useSetOpenFail()

  // 成功時のSnackBar削除時のコールバック
  const handleCloseSuccess = (
    _event?: SyntheticEvent,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccess(false)
  }

  // 失敗時のSnackBar削除時のコールバック
  const handleCloseFail = (_event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }
    setOpenFail(false)
  }

  return (
    <>
      <SuccessSnackBar
        message={`${props.successMessage ?? '仮登録が完了しました'}`}
        open={openSuccess}
        setOpen={setOpenSuccess}
        handleClose={handleCloseSuccess}
      />
      <FailSnackBar
        message={`${props.errorMessage ?? '仮登録に失敗しました'}`}
        open={openFail}
        setOpen={setOpenFail}
        handleClose={handleCloseFail}
        duration={5000}
      />
    </>
  )
}
