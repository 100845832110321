import React, {
  useEffect,
  useState,
  SyntheticEvent,
  forwardRef,
  useCallback
} from 'react'
import { Grid } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Container from '@mui/material/Container'
import { Snackbar, Typography } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Skeleton from '@mui/material/Skeleton'

import { useNavigate } from 'react-router-dom'

import CommonDialog from 'components/Molecules/CommonDialog'
import {
  deleteInspectionStartEvent,
  registerInspectionStartEvent
} from 'api/cogniteApi'
import {
  useInspectionTypes,
  useSetInspectionTypes
} from 'contexts/InspectionTypesContext'
import { useAuthUser } from 'hooks/useAuthUser'
import {
  deleteNotes,
  deleteTimeseries,
  deleteTimestamp,
  disableRegistrationflag,
  enableRegistrationflag,
  getInspectionTypes,
  TypeIdSchema,
  registerInspectionResultToCognite,
  RegistrationflagType
} from 'api/backendApi'
import { StartButtons } from 'components/Molecules/StartButtons'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import { useInspectionEvents } from 'hooks/useInspectionEvents'
import { CogniteEvent } from '@cognite/sdk'

const theme = createTheme()
export interface handleRegisterButtonProps {
  versionId: number
}
export interface handleDeleteButtonProps {
  versionId: number
}

export const Home: React.FC = () => {
  // contextから以前使用していたidTokenを取得
  // 非同期的に新しいidTokenも取得し、idTokenにsetする
  // これにより、idToken取得を待たずにAPIコールが可能になる
  const { newIdToken, account } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])
  // contextからInspectionTypesを取得
  const inspectionTypes = useInspectionTypes()
  const setInspectionTypes = useSetInspectionTypes()
  // 本登録完了時に種別IDの再取得を行い、画面を再描画する
  const [reloadFlag, setReloadFlag] = useState<boolean>(false)
  const [isStarting, setIsStarting] = useState<boolean>(false)
  useEffect(() => {
    if (idToken.length === 0) {
      return
    }
    const fetchInspectionTypes = async (): Promise<void> => {
      const result = await getInspectionTypes({ idToken })
      setInspectionTypes(result)
      setReloadFlag(false)
    }
    fetchInspectionTypes().catch((error) => {
      console.log(error)
      throw new Error('error')
    })

    // inspectionTypesはcontextに保存し、GroupList.tsxなどでも使うので、
    // returnでsetInspectionTypes([])を実行させない
  }, [idToken, reloadFlag, setInspectionTypes])

  // 種別ごとのボタンのdisableを管理する変数。何も点検が進行していない場合は全てのボタンを押すことができ、
  // いずれかの種別での点検が進行中の場合、その種別以外のボタンはdisableとする
  const [isDisableButtons, setIsDisableButtons] = useState<boolean[]>([])
  useEffect(() => {
    const tempList: boolean[] = []
    const isInspectionInProgress = inspectionTypes.some(
      (inspectionType) =>
        inspectionType.registrationflag === RegistrationflagType.NotRegistered
    )
    if (isInspectionInProgress) {
      inspectionTypes.forEach((inspectionType) => {
        if (
          inspectionType.registrationflag === RegistrationflagType.NotRegistered
        ) {
          tempList.push(false)
        } else {
          tempList.push(true)
        }
      })
    } else {
      inspectionTypes.forEach(() => {
        tempList.push(false)
      })
    }
    setIsDisableButtons(tempList)
  }, [inspectionTypes])

  const setIsDisableAllButtons = useCallback(
    (props: boolean) => {
      const tempList: boolean[] = []
      isDisableButtons.forEach(() => {
        tempList.push(props)
      })
      setIsDisableButtons(tempList)
    },
    [isDisableButtons]
  )

  /**
   * グループページへのページ遷移を行う関数
   *
   */
  const navigateToGroupList = (): void => {
    if (
      selectedInspectionTypes == null ||
      Number.isNaN(selectedInspectionTypes.versionId)
    ) {
      throw new Error('selectedInspectionTypes is invalid')
    }
    navigate(`/inspectionInput/${selectedInspectionTypes.type}`)
  }

  // ページ遷移を行うため、navigateをcall
  const navigate = useNavigate()
  // 新規点検を開始
  const startNewInspection = async (): Promise<void> => {
    if (account.name == null || account.name === '') {
      throw new Error('Account information is not available.')
    }
    const inspectorName: string = account.name.split('(')[0]
    setIsStarting(true)

    // 新規ボタンを押した際に登録された対応するInspectionTypesの有効性を評価
    if (
      selectedInspectionTypes == null ||
      Number.isNaN(selectedInspectionTypes.versionId)
    ) {
      throw new Error('selectedInspectionTypes is invalid')
    }

    // 点検開始を表すイベントをcogniteに登録する
    await registerInspectionStartEvent({
      inspectorName,
      versionId: String(selectedInspectionTypes.versionId)
    }).catch((error) => {
      console.log(error)
      throw new Error('registerInspectionStartEvent is failed')
    })

    // 点検開始と同時に未登録フラグを立てる
    await enableRegistrationflag({
      versionId: selectedInspectionTypes.versionId,
      idToken
    }).catch((error) => {
      console.log(error)
      throw new Error('enableRegistrationflag is failed')
    })

    setIsStarting(false)
    navigateToGroupList()
  }

  // 直前の点検を再開
  const restartPreviousInspection = (): void => {
    navigateToGroupList()
  }

  // 押された 登録ボタンに対応する種別のversionIdを保存するためのstate
  const [registeringVersionId, setRegisteringVersionId] = useState<number>(0)
  // 登録確認ボタンを押した際のコールバック
  const registerInspectionResult = (): void => {
    if (registeringVersionId === 0) {
      // registeringVersionIdは再開、登録、削除を押下時に、0から別の値にセットされる。
      // 仮に0のままコールバックが呼ばれた場合、エラーを発生させる
      throw new Error('registeringVersionId is not invalid.')
    }
    setIsRegisterConfirmation(false)
    setIsDisableAllButtons(true)
    registerInspectionResultToCognite({
      versionId: registeringVersionId,
      idToken
    })
      .then(() => {
        setIsDisableAllButtons(false)
        setRegisteringVersionId(0)
        setOpenSuccess(true)
        // 本登録完了時に種別IDの再取得を行い、画面の再描画を行う
        setReloadFlag(true)
      })
      .catch((error) => {
        console.log(error)
        setIsDisableAllButtons(false)
        setOpenFail(true)
        throw new Error('Data registration to Cognite is failed')
      })
  }

  const { inspectionEvents } = useInspectionEvents({
    maxEvents: 100
  })
  // 点検削除が実施された際のコールバック
  const deleteInspectionResult = useCallback(() => {
    /**
     * versionIdを指定した点検開始イベントのうち、点検終了していないもの(endTimeがnull)を取得しIDを返す関数
     *
     * @param {CogniteEvent[]} eventList
     * @param {string} versionId
     * @return {number[]} IDのリスト
     */
    const getLatestInspectionStartEventId = (
      eventList: CogniteEvent[],
      versionId: string
    ): number[] => {
      // eventのうち、versionIdが一致し、endTimeがundefined or null(点検完了していない)ものを抽出する
      const filteredEventList = eventList.filter((event) => {
        // 二重否定によりbooleanをreturnさせる
        return !!(
          event.metadata?.versionId === versionId && event.endTime == null
        )
      })
      const idList: number[] = []
      filteredEventList.forEach((event) => {
        idList.push(event.id)
      })
      return idList
    }

    /**
     * AWS上に保存された最終点検日時、時系列データ、備考を削除する関数
     *
     */
    const deleteTempRegisteredDataOnAws = async (): Promise<void> => {
      const deleteApiProps = {
        versionId: registeringVersionId,
        idToken
      }
      await Promise.all([
        deleteTimestamp(deleteApiProps),
        deleteTimeseries(deleteApiProps),
        deleteNotes(deleteApiProps)
      ]).catch((error) => {
        console.log(error)
        setIsDisableAllButtons(false)
        throw new Error('deleteTempRegisteredData is failed')
      })
    }

    /**
     * 削除ボタン押下時の処理をまとめた関数
     *
     */
    const deleteTempRegisteredData = async (): Promise<void> => {
      // 削除処理中はボタンをdisableにする
      setIsDisableAllButtons(true)
      await deleteTempRegisteredDataOnAws()
      // バージョンIDを引数に渡し、対応する点検開始イベントを取得する
      const eventIds = getLatestInspectionStartEventId(
        inspectionEvents,
        String(registeringVersionId)
      )
      // イベント削除を行ったあと、未登録フラグの修正で失敗した場合、該当する点検が存在しない場合が考えられる。
      // そのため、点検開始イベントリストが1つ以上の要素を持つ場合にのみイベント削除を行う
      if (eventIds.length > 0) {
        await deleteInspectionStartEvent({ eventId: eventIds[0] })
      }
      // 全ての削除が完了したら、登録済みフラグをfalseにする
      await disableRegistrationflag({
        versionId: registeringVersionId,
        idToken
      }).catch((error) => {
        console.log(error)
        setIsDisableAllButtons(false)
        throw new Error('disableRegistrationflag is failed')
      })
      // registeringVersionIdは再開、登録、削除を押下時に、0から別の値にセットされる。
      // 仮に0のままコールバックが呼ばれた場合、エラーを発生させる
      if (registeringVersionId === 0) {
        throw new Error('registeringVersionId is not invalid.')
      }
      // 以下の処理をdeleteTempRegisteredData()配下に書くことも出来るが、Promiseのresolveを待たずに動作してしまうため、
      // 削除押下後も削除ボタンなどが残ったままになってしまう。
      // ボタンのdisableを解除
      setIsDisableAllButtons(false)
      // 一連の処理が完了したので、registeringVersionIdを初期値に戻す
      setRegisteringVersionId(0)
      setReloadFlag(true)
    }

    setIsDeleteConfirmation(false)
    deleteTempRegisteredData().catch((error) => {
      console.log(error)
      throw new Error('deleteTempRegisteredData is failed')
    })
  }, [idToken, inspectionEvents, registeringVersionId, setIsDisableAllButtons])

  // 点検結果出力ページに遷移
  const goToInspectionResult = (): void => {
    navigate('/inspectionResultsList')
  }
  // 新規から、途中からを押された際に、選択された種別IDなどの情報を保存する
  const [selectedInspectionTypes, setSelectedInspectionTypes] =
    useState<TypeIdSchema | null>(null)
  // 確認ダイアログを表示させるかどうかを表すstateを定義
  // 新規を押した場合に用いるstate
  const [isNewConfirmation, setIsNewConfirmation] = useState(false)

  // 新規ボタンを押した際にダイアログ表示＆押された種別に対応するInspectionTypesを登録する
  const handleNewConfirmation = (props: TypeIdSchema): void => {
    setSelectedInspectionTypes(props)
    setIsNewConfirmation(true)
  }
  const closeNewConfirmationDialog = (): void => {
    setIsNewConfirmation(false)
  }
  // 続きからを押した場合に用いるstate
  const [isContinueConfirmation, setIsContinueConfirmation] = useState(false)

  // 再開ボタンを押した際にダイアログ表示＆押された種別に対応するInspectionTypesを登録する
  const handleContinueConfirmation = (props: TypeIdSchema): void => {
    setSelectedInspectionTypes(props)
    setIsContinueConfirmation(true)
  }
  const closeContinueConfirmationDialog = (): void => {
    setIsContinueConfirmation(false)
    setRegisteringVersionId(0)
  }
  // 登録を押した場合に用いるstate
  const [isRegisterConfirmation, setIsRegisterConfirmation] = useState(false)
  const closeRegisterConfirmationDialog = (): void => {
    setIsRegisterConfirmation(false)
    setRegisteringVersionId(0)
  }

  // 登録ボタンが押されたとき、選ばれた種別のversionIdを一時保存しておく。
  // その後cognite本登録APIをcallする際に、一時保存したこれらIDを元に登録先を決定する
  const handleRegisterButton = (props: handleRegisterButtonProps): void => {
    setRegisteringVersionId(props.versionId)
    // ポップアップを表示
    setIsRegisterConfirmation(true)
  }

  // 点検削除確認ダイアログを表示させるかどうかを表すstate
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false)
  // ダイアログ上でキャンセルされた場合のハンドラ
  const closeDeleteConfirmationDialog = (): void => {
    setIsDeleteConfirmation(false)
    setRegisteringVersionId(0)
  }
  // 点検結果削除ボタンが押されたときのハンドラ
  // 削除ボタン押下時に選ばれた種別のversionIdをregisteringVersionIdに保存する。
  // その後確認ダイアログでOKが押された場合に、registeringVersionIDを元に処理を進める
  const handleDeleteButton = (props: handleDeleteButtonProps): void => {
    setRegisteringVersionId(props.versionId)
    setIsDeleteConfirmation(true)
  }

  // cogniteへの登録が正常終了した場合にsnackbarによる通知を行うための変数群
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFail, setOpenFail] = useState(false)
  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  // 成功時SnackBar削除時のコールバック
  const handleCloseSuccess = (
    _event?: SyntheticEvent,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccess(false)
  }
  // 失敗時のSnackBar削除時のコールバック
  const handleCloseFail = (_event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }
    setOpenFail(false)
  }

  interface InspectionTypeButtonsProps {
    inspectionType: TypeIdSchema
    isDisableButtons: boolean
  }
  /**
   * 点検種別名とstartButtonsを表示するコンポーネント
   *
   * @param {InspectionTypeButtonsProps} props
   * @return {Element}
   */
  const InspectionTypeButtons: React.FC<InspectionTypeButtonsProps> = (
    props
  ) => {
    return (
      <div key={props.inspectionType.type}>
        <ListItem>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container justifyContent="flex-start">
                <Grid item>
                  <Typography
                    sx={{ maxWidth: 100 }}
                  >{`${props.inspectionType.type}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {/* 未登録時は新規、仮登録時は途中から&登録ボタンを表示させる */}
              <StartButtons
                handleNewInspectionButton={handleNewConfirmation}
                handleContinueInspectionButton={handleContinueConfirmation}
                handleRegisterButton={handleRegisterButton}
                handleDeleteButton={handleDeleteButton}
                typeIdElement={props.inspectionType}
                isDisableButtons={props.isDisableButtons}
              />
            </Grid>
          </Grid>
        </ListItem>
      </div>
    )
  }

  /**
   * loading中はｓkeletonを、loading後は各種点検種別ごとのStartButtonsを表示するコンポーネント
   *
   * @return {Element}
   */
  const InspectionTypeButtonsWithSkeleton: React.FC = () => {
    if (inspectionTypes.length > 0) {
      return (
        <div>
          {inspectionTypes.map((element, index) => (
            <InspectionTypeButtons
              inspectionType={element}
              isDisableButtons={isDisableButtons[index]}
            />
          ))}
        </div>
      )
    } else {
      return (
        // skeletonの見た目を揃えるため、表示するものと全く同じJSXを記述している。
        // TODO スマートに記述する
        <div>
          <ListItem>
            <Grid container justifyContent="space-between">
              <Grid item></Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Skeleton variant="rectangular" animation="wave">
                      <Button sx={{ width: 210 }}>高さ調節用文字列</Button>
                    </Skeleton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </div>
      )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            alignItems: 'center'
          }}
        >
          <List>
            <InspectionTypeButtonsWithSkeleton />
            <ListItem>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container justifyContent="flex-start">
                    <Grid item>
                      <ListItemText primary={'点検結果'} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={goToInspectionResult}
                        sx={{ width: 210 }}
                      >
                        結果出力ページへ
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <CommonDialog
            message="新規点検を開始しますか？"
            isOpen={isNewConfirmation}
            isYesDisabled={isStarting}
            doYes={startNewInspection}
            doNo={closeNewConfirmationDialog}
          />
          <CommonDialog
            message="直前の点検を再開しますか？"
            isOpen={isContinueConfirmation}
            doYes={restartPreviousInspection}
            doNo={closeContinueConfirmationDialog}
          />
          <CommonDialog
            message="点検結果をcogniteに登録しますか？"
            isOpen={isRegisterConfirmation}
            doYes={registerInspectionResult}
            doNo={closeRegisterConfirmationDialog}
          />
          <CommonDialog
            message="点検結果を削除しますか？"
            isOpen={isDeleteConfirmation}
            doYes={deleteInspectionResult}
            doNo={closeDeleteConfirmationDialog}
          />
          <Snackbar
            open={openSuccess}
            autoHideDuration={2000}
            onClose={() => setOpenSuccess(false)}
          >
            <Alert
              onClose={handleCloseSuccess}
              severity="success"
              sx={{ width: '100%' }}
            >
              Cogniteへの登録処理が完了しました
            </Alert>
          </Snackbar>
          <Snackbar
            open={openFail}
            autoHideDuration={2000}
            onClose={() => setOpenFail(false)}
          >
            <Alert
              onClose={handleCloseFail}
              severity="error"
              sx={{ width: '100%' }}
            >
              Cogniteへの登録処理が失敗しました
            </Alert>
          </Snackbar>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
