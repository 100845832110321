import { Timestamp } from '@cognite/sdk'
import { Eventflag, ItemsSchema } from 'api/backendApi'
import { getLatestDatapoint } from 'api/cogniteApi'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

interface usePreviousResultProps {
  inspectionDate: Timestamp | undefined
  task: ItemsSchema
}

interface usePreviousResultReturn {
  previousValue: string
  previousDate: string
  previousTime: string
}

export const usePreviousResult = (
  props: usePreviousResultProps
): usePreviousResultReturn => {
  const { data } = useQuery(
    `latestDatapoint-${props.task.cogniteExternalId}`,
    async () => {
      return await getLatestDatapoint({
        externalId: props.task.cogniteExternalId,
        before: props.inspectionDate
      })
    }
  )

  // 点検項目に表示するための前回点検の日時及び値を定義
  const [previousValue, setPreviousValue] = useState<string>('')
  const [previousDate, setPreviousDate] = useState<string>('')
  const [previousTime, setPreviousTime] = useState<string>('')
  useEffect(() => {
    if (data == null || typeof data.datapoints[0] === 'undefined') {
      return
    }
    // 選択肢の点検項目の場合、0, 1を意味する文字列を表示させる必要がある。その文字列を作るための関数
    const createPreviousValue = (data: string | number): string => {
      // 0-9に対応する表示を意味する列名
      // keyofをつけないと、props.task[keyName]でエラーが発生する
      const displayTable: Array<keyof ItemsSchema> = [
        'zeroDisplay',
        'oneDisplay',
        'twoDisplay',
        'threeDisplay',
        'fourDisplay',
        'fiveDisplay',
        'sixDisplay',
        'sevenDisplay',
        'eightDisplay',
        'nineDisplay'
      ]

      if (props.task.eventflag === Eventflag.InputNumber) {
        return String(data)
      } else if (props.task.eventflag === Eventflag.Select) {
        try {
          // data がnumberまたは0~9のstringであることを前提としている。
          // それ以外の場合、エラーを発生させる
          const index: number = Number(data)
          const showValue = props.task[displayTable[index]]
          if (typeof showValue !== 'undefined') {
            return String(showValue)
          } else {
            return ''
          }
        } catch (error) {
          console.log(error)
          throw new Error('given data format is invalid (not 0 ~ 9)')
        }
      } else {
        throw new Error('eventflag is invalid')
      }
    }

    // 日時及び値をstateに保存
    setPreviousValue(createPreviousValue(data.datapoints[0].value))
    setPreviousDate(
      new Date(data.datapoints[0].timestamp).toLocaleDateString('ja-JP')
    )
    const options = { hour12: false }
    setPreviousTime(
      new Date(data.datapoints[0].timestamp).toLocaleTimeString(
        'ja-JP',
        options
      )
    )

    return () => {
      setPreviousValue('')
      setPreviousDate('')
      setPreviousTime('')
    }
  }, [data, props.task])

  return {
    previousValue,
    previousDate,
    previousTime
  }
}
