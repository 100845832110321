import React, { Component, useRef, useState } from 'react'
import { Button, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ResultSheet } from 'components/Molecules/ResultSheet'
import ReactToPrint from 'react-to-print'
import { approveInspectionStartEvent } from 'api/cogniteApi'
import { useAuthUser } from 'hooks/useAuthUser'
import { useEventIdParam } from 'hooks/useUrlParam'
import { useQueryClient } from 'react-query'
import { useIsApproved } from 'hooks/useIsApproved'
import { APPROVER_GROUP_ID } from 'consts/commonConst'

const theme = createTheme({
  palette: {
    background: {
      default: '#c0c0c0'
    }
  }
})

// react-to-printの仕様上、このcomponentだけクラスとして定義する必要がある
class ComponentToPrint extends Component {
  public render(): JSX.Element {
    return (
      <>
        <ResultSheet />
      </>
    )
  }
}

export const InspectionResult: React.FC = () => {
  const conponentRef = useRef(null)
  const { groups, account } = useAuthUser()
  const userName = account?.name?.split('(')[0] ?? ''
  const { eventId } = useEventIdParam()
  const isApproved = useIsApproved(eventId)
  const queryClient = useQueryClient()
  const [isRegistering, setIsRegistering] = useState(false)
  const isApprover = groups.some((group) => group === APPROVER_GROUP_ID)

  const onClick = async (): Promise<void> => {
    setIsRegistering(true)
    await approveInspectionStartEvent({
      id: eventId,
      groups,
      name: userName
    }).catch((error) => {
      throw new Error(error)
    })
    // CogniteのEventの反映が間に合わず承認済みにならないことがあるので1秒待つ
    await new Promise((resolve) => setTimeout(resolve, 1000))
    await queryClient.invalidateQueries('inspectionEvents').catch((error) => {
      throw new Error(error)
    })
    setIsRegistering(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          displayPrint="none"
          sx={{
            marginTop: 4,
            alignItems: 'center',
            width: '810px'
          }}
        >
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Grid container spacing={1} direction="row">
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      void onClick()
                    }}
                    disabled={isApproved || isRegistering || !isApprover}
                  >
                    {isRegistering
                      ? '登録中..'
                      : isApproved
                      ? '承認済み'
                      : '承認'}
                  </Button>
                </Grid>
                <Grid item>
                  <ReactToPrint
                    trigger={() => (
                      <Box displayPrint="none" textAlign="right">
                        <Button variant="contained">印刷</Button>
                      </Box>
                    )}
                    content={() => conponentRef.current}
                    documentTitle={'InspectionResults.pdf'}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ComponentToPrint ref={conponentRef} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
