export type Environment =
  | 'development'
  | 'test'
  | 'production'
  | 'monthly-production'
  | 'rem-daily'
  | 'kp-daily'
export interface EnvConst {
  ENVIRONMENT: Environment
  DATASET_ID: number
  FACILITY_NAME: string
  BACKEND_API_ROOT_URL: string
  CDF_PROJECT: string
}
export const commonConst = {
  CLUSTER: 'asia-northeast1-1',
  AZURE_APP_ID: '9e407f0f-d5dc-4896-942d-9e92986beafd',
  AZURE_TENANT_ID: 'e171a14d-4552-444f-ae6d-8a28cadd3bfb'
}
export const APPROVER_GROUP_ID = '7fdd8cfd-3917-435b-8d03-235e91d5e661'
