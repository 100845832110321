import {
  CogniteClient,
  CogniteEvent,
  Datapoints,
  EventFilterRequest,
  EventSearchRequest,
  ExternalEvent,
  ListResponse,
  Timestamp
} from '@cognite/sdk'
import { getToken, baseUrl } from 'auth/auth'

import { envConst } from 'consts/envConst'
import { APPROVER_GROUP_ID } from 'consts/commonConst'

// cogniteAPIのクライアントを生成
export const getCogniteClient = async (): Promise<CogniteClient> => {
  const project = envConst.CDF_PROJECT
  const cogniteClient = new CogniteClient({
    project,
    appId: 'msal-react-app',
    baseUrl,
    getToken
  })
  await cogniteClient.authenticate()

  return cogniteClient
}

// 点検開始イベントを取得する
export const getInspectionStartEvent = async (
  eventNum: number,
  cursor?: string
): Promise<ListResponse<CogniteEvent[]>> => {
  const client = await getCogniteClient()
  const event: EventFilterRequest = {
    filter: {
      type: '運転管理記録',
      subtype: '点検開始',
      dataSetIds: [{ id: envConst.DATASET_ID }]
    },
    limit: eventNum,
    cursor,
    sort: {
      startTime: 'desc'
    }
  }

  try {
    const events = await client.events.list(event)
    return events
  } catch (error) {
    console.error(error)
    throw new Error('retrieve InspectionStartEvent is failed.')
  }
}

export interface GetEventByIdProps {
  eventId: number
}
// IDを元に単一Eventを取得する
export const getEventsById = async (
  props: GetEventByIdProps
): Promise<CogniteEvent> => {
  if (Number.isNaN(props.eventId)) {
    throw new Error('eventId is undefined.')
  }

  const client = await getCogniteClient()
  try {
    const event = await client.events.retrieve([{ id: props.eventId }])
    // 単一のイベントのみ返すことを想定
    if (event.length === 0) {
      throw new Error('event is not found.')
    } else if (event.length > 1) {
      throw new Error('event is duplicated.')
    } else {
      return event[0]
    }
  } catch (error) {
    console.log(error)
    throw new Error('retrieve EventsById is failed.')
  }
}

export interface GetNotesByMachineIdProps {
  machineIds: string[]
  startTime: Timestamp
  endTime: Timestamp
  eventId: string
}
export const getNotesByMachineId = async (
  props: GetNotesByMachineIdProps
): Promise<string[]> => {
  if (props.machineIds.length === 0) {
    throw new Error('machineIds is undefined.')
  }

  const client = await getCogniteClient()
  const notes: string[] = []
  for (const machineId of props.machineIds) {
    // 修正機能導入前は備考イベント取得にはイベントの作成日時だけをキーに検索をかければよかったが、
    // 点検時間の間以外に作られた備考イベントも取得する必要が出てきた。
    // 従来形式の備考イベントとの互換性を図るため、まずはmetadata中のinspectionStartEventのID値(新形式の備考イベントのみに存在)を読み取り
    // 見つからなかった場合は、従来どおり作成日時で検索をかけ、
    // それでも見つからなかった場合に備考が存在しないと判断を行う。
    const eventQuery: EventSearchRequest = {
      filter: {
        type: '運転管理記録',
        subtype: '備考',
        metadata: {
          facilityName: envConst.FACILITY_NAME,
          machineId,
          inspectionStartEvent: props.eventId
        }
      }
    }
    try {
      const eventsByMachineId = await client.events
        .search(eventQuery)
        .catch((error) => {
          console.log(error)
          throw new Error('cognite event search API is failed')
        })
      if (
        eventsByMachineId.length > 0 &&
        eventsByMachineId[0].description != null
      ) {
        notes.push(eventsByMachineId[0].description)
      } else {
        const secondEventQuery: EventSearchRequest = {
          filter: {
            type: '運転管理記録',
            subtype: '備考',
            dataSetIds: [{ id: envConst.DATASET_ID }],
            metadata: {
              machineId
            },
            createdTime: {
              max: props.endTime,
              min: props.startTime
            }
          }
        }
        const eventsByStartEvent = await client.events
          .search(secondEventQuery)
          .catch((error) => {
            console.log(error)
            throw new Error('cognite event search API is failed')
          })
        if (
          eventsByStartEvent.length > 0 &&
          eventsByStartEvent[0].description != null
        ) {
          notes.push(eventsByStartEvent[0].description)
        } else {
          notes.push('')
        }
      }
    } catch (error) {
      console.error(error)
      throw new Error('retrieve EventsById is failed.')
    }
  }

  if (notes.length !== props.machineIds.length) {
    throw new Error('notes and machineIds length is not the same.')
  }
  return notes
}

const NINE_HOUR_MILLISECONDS = 32400000
export interface registerInspectionStartEventProps {
  inspectorName: string
  versionId: string
}
// 点検開始イベントをcogniteへ登録する
export const registerInspectionStartEvent = async (
  props: registerInspectionStartEventProps
): Promise<CogniteEvent[]> => {
  // 点検者名が空欄の場合はエラー
  if (props.inspectorName === '') {
    throw new Error('inspectorName is empty.')
  }

  const client = await getCogniteClient()
  const event: ExternalEvent[] = [
    {
      dataSetId: envConst.DATASET_ID,
      startTime: new Date(),
      type: '運転管理記録',
      subtype: '点検開始',
      metadata: {
        // TODO 複数施設で運用される場合、点検者に応じて施設名を変更する必要あり
        facilityName: envConst.FACILITY_NAME,
        inspectorName: props.inspectorName,
        versionId: props.versionId
      }
    }
  ]
  try {
    const createdEvent = await client.events.create(event)
    return createdEvent
  } catch (error) {
    console.log(error)
    throw new Error('registerInspectionStartEvent is failed.')
  }
}

export interface deleteInspectionStartEventProps {
  eventId: number
}

/**
 * 点検開始イベントをcogniteから削除する
 *
 * @param {deleteInspectionStartEventProps} props
 * @return {Promise<void>}
 */
export const deleteInspectionStartEvent = async (
  props: deleteInspectionStartEventProps
): Promise<void> => {
  if (Number.isNaN(props.eventId)) {
    throw new Error('eventId is undefined.')
  }

  const client = await getCogniteClient()
  await client.events.delete([{ id: props.eventId }]).catch((error) => {
    console.error(error)
    throw new Error('deleteInspectionStartEvent is failed.')
  })
}

// 計器タグ名から対応するAssetのIDを取得する
export interface getAssetIdByTagProps {
  tagName: string
}
export const getAssetIdByTag = async (
  props: getAssetIdByTagProps
): Promise<number> => {
  if (props.tagName === '') {
    throw new Error('tagName is empty.')
  }

  const client = await getCogniteClient()
  const searchQuery = {
    filter: {
      name: props.tagName
    },
    limit: 1
  }

  try {
    const assets = await client.assets.search(searchQuery)
    // 該当するAssetがない場合はエラー
    if (assets.length === 0) {
      throw new Error('There is no event with this tagName.')
      // 該当するAssetが複数ある場合はエラー
      // TODO 仕様を要検討。他Assetの名前を包含したAssetがある場合はどうするか
    } else if (assets.length > 1) {
      throw new Error('There are multiple events with this tagName.')
    }
    return assets[0].id
  } catch (error) {
    console.log(error)
    throw new Error('getAssetIdByTag is failed.')
  }
}

export interface registerNotesEventProps {
  inspectorName: string
  placeName: string
  machineId: string
  notes: string
  assetId: number
}
// 備考をEventsとしてcogniteに登録
// 紐付けるAssetのIDも引数として渡す必要がある
export const registerNotesEvent = async (
  props: registerNotesEventProps
): Promise<CogniteEvent[]> => {
  if (
    props.inspectorName === '' ||
    props.placeName === '' ||
    props.notes === '' ||
    props.machineId === '' ||
    Number.isNaN(props.assetId)
  ) {
    throw new Error('registerNotesEvent props is invalid')
  }

  const client = await getCogniteClient()
  const event: ExternalEvent[] = [
    {
      assetIds: [props.assetId],
      startTime: new Date(),
      type: '運転管理記録',
      subtype: '備考',
      metadata: {
        facilityName: envConst.FACILITY_NAME,
        inspectorName: props.inspectorName,
        placeName: props.placeName,
        machineId: props.machineId,
        notes: props.notes
      }
    }
  ]

  try {
    const createEvent = await client.events.create(event)
    return createEvent
  } catch (error) {
    console.log(error)
    throw new Error('registerNotesEvent is failed.')
  }
}

export interface RegisterTimeseriesProps {
  externalId: string
  value: number
  timestamp?: number
}
// 数値を登録する点検項目について、cogniteのtimeseriesに登録する
export const registerTimeseries = async (
  props: RegisterTimeseriesProps
): Promise<void> => {
  if (props.externalId === '' || Number.isNaN(props.value)) {
    throw new Error('ExternalId or value is invalid')
  }
  const client = await getCogniteClient()
  const timestamp =
    props.timestamp == null
      ? Date.now() - NINE_HOUR_MILLISECONDS
      : props.timestamp
  const dataPoint = [
    {
      externalId: props.externalId,
      datapoints: [
        {
          timestamp,
          value: props.value
        }
      ]
    }
  ]

  try {
    await client.datapoints.insert(dataPoint)
  } catch (error) {
    console.log(error)
    throw new Error('registerTimeseries is failed.')
  }
}

export interface GetMultipleDataPointsByStartAndEndTimeProps {
  externalIds: string[]
  startTime: Timestamp
  endTime: Timestamp
}
// 指定した時間帯のうち最新のデータを取得する。
export const getMultipleDataPointsByStartAndEndTime = async (
  props: GetMultipleDataPointsByStartAndEndTimeProps
): Promise<string[]> => {
  if (props.externalIds.length === 0) {
    return []
  }

  const client = await getCogniteClient()
  // externalIdsを100個ずつに分割する
  const iterNum = Math.floor(props.externalIds.length / 100) + 1
  let tempDataPoints: Datapoints[] = []
  // 最大で取得できるポイント数は100ポイントまでなので、分割して取得する
  for (let i = 0; i < iterNum; i++) {
    const searchQuery = []
    for (const externalId of props.externalIds.slice(i * 100, (i + 1) * 100)) {
      searchQuery.push({
        externalId,
        before: props.endTime
      })
    }
    // beforeで設定した時刻より前のうち最新のDatapointを取得する
    // awaitしないと、datapointsの配列順が引数のexternalIdsの順番と一致しなくなる
    const dataPoints = await client.datapoints.retrieveLatest(searchQuery)
    tempDataPoints = tempDataPoints.concat(dataPoints)
  }

  if (tempDataPoints.length !== props.externalIds.length) {
    throw new Error('tempDataPoints and externalIds length is not the same.')
  }

  // Datapoints[]からDatapoint: stringのみを抽出する
  const datapointsArray: string[] = []
  for (const dataPoint of tempDataPoints) {
    // 該当するデータがない場合は空の文字列を返す
    if (dataPoint.datapoints.length === 0) {
      datapointsArray.push('')
    } else {
      // 点検開始時刻より以前のDatapointだった場合は今回の点検結果ではないため、空の文字列を返す
      const startTimeDate =
        typeof props.startTime === 'number'
          ? new Date(props.startTime)
          : props.startTime
      if (dataPoint.datapoints[0].timestamp < startTimeDate) {
        datapointsArray.push('')
      } else {
        // Datapointの値を追加
        datapointsArray.push(String(dataPoint.datapoints[0].value))
      }
    }
  }

  return datapointsArray
}

// TimeseriesのexternalIdから最新のDatapointを取得する
// beforeを入力しない場合、最新のデータを取得する
export interface getLatestDatapointProps {
  externalId: string
  before?: Timestamp
}
export const getLatestDatapoint = async (
  props: getLatestDatapointProps
): Promise<Datapoints> => {
  if (props.externalId === '') {
    throw new Error('externalId is invalid')
  }
  const getLatestDatapointProps = Object.assign(
    {
      externalId: '',
      // beforeで設定した時刻より前のうち最新のDatapointを取得する
      before: new Date()
    },
    props
  )

  const client = await getCogniteClient()
  const searchQuery = [getLatestDatapointProps]

  try {
    const datapoints = await client.datapoints.retrieveLatest(searchQuery)
    if (datapoints.length === 0) {
      throw new Error('There is no dataPoint in dataPoints')
    }
    return datapoints[0]
  } catch (error) {
    console.log(error)
    throw new Error('registerTimeseries is failed.')
  }
}

export interface UpdateCogniteTimeseriesProps {
  externalId: string
  value: number
  start: number
  end: number
}

export const updateCogniteTimeseries = async (
  props: UpdateCogniteTimeseriesProps
): Promise<void> => {
  if (props.externalId === '') {
    throw new Error('cogniteExternalId is invalid')
  }

  const Datapoint: Datapoints = await getLatestDatapoint({
    externalId: props.externalId,
    before: props.end
  })
  const timestamp = Datapoint.datapoints[0].timestamp.getTime()
  // 点検結果が開始時刻-終了時刻の間で登録されていなかった場合
  if (timestamp < props.start) {
    await registerTimeseries({
      externalId: props.externalId,
      value: props.value,
      timestamp: (props.start + props.end) / 2
    }).catch(() => {
      throw new Error('registerTimeseries is failed')
    })
    return
  }

  const client = await getCogniteClient()
  await client.datapoints
    .insert([
      {
        externalId: props.externalId,
        datapoints: [{ timestamp, value: props.value }]
      }
    ])
    .catch(() => {
      throw new Error('insertDatapoints is failed')
    })
}

export interface UpdateCognitEventDescriptionProps {
  event: CogniteEvent
  value: string
}

// TODO 備考イベント削除APIとupdateAPIに分割し、空文字列だった場合削除APIを呼び出すようにする
export const updateCogniteEventDescription = async (
  props: UpdateCognitEventDescriptionProps
): Promise<void> => {
  const client = await getCogniteClient()
  // 空文字列が渡された場合、該当の備考イベントを削除する
  if (props.value === '') {
    await client.events
      .delete([
        {
          id: props.event.id
        }
      ])
      .catch((error) => {
        console.log(error)
        throw new Error('Delete Cognite Evenet is failed')
      })
  } else {
    await client.events
      .update([
        {
          id: props.event.id,
          update: {
            description: {
              set: props.value
            }
          }
        }
      ])
      .catch((error) => {
        console.log(error)
        throw new Error('UpdateCogniteEventDescription is failed.')
      })
  }
}

interface UpdateCogniteEventDescriptionByMachineId {
  startEvent: CogniteEvent
  machineId: string
  value: string
  assetId: number
}

export const updateCogniteEventDescriptionByMachineId = async (
  props: UpdateCogniteEventDescriptionByMachineId
): Promise<void> => {
  if (props.machineId === '') {
    throw new Error('machineId is invalid')
  }
  const client = await getCogniteClient()
  const targetEvents = await client.events
    .search({
      filter: {
        metadata: {
          machineId: props.machineId,
          inspectionStartEvent: String(props.startEvent.id)
        }
      }
    })
    .catch((error) => {
      console.log(error)
      throw new Error('search cognite Event is failed')
    })

  // 既存のeventが存在するかどうかで処理を切り替える
  if (targetEvents.length !== 0) {
    await updateCogniteEventDescription({
      // targetEventsが複数無いはずなので、0番目の要素を渡す
      event: targetEvents[0],
      value: props.value
    }).catch((error) => {
      console.log(error)
      throw new Error('updateCogniteEventDescription is failed')
    })
    // 既存の備考イベントがない場合、新たにイベントを作成する。
  } else {
    const event: ExternalEvent[] = [
      {
        dataSetId: envConst.DATASET_ID,
        type: '運転管理記録',
        subtype: '備考',
        description: props.value,
        assetIds: [props.assetId],
        metadata: {
          facilityName: envConst.FACILITY_NAME,
          inspectorName:
            props.startEvent.metadata?.inspectorName != null
              ? props.startEvent.metadata?.inspectorName
              : '',
          machineId: props.machineId,
          inspectionStartEvent: String(props.startEvent.id)
        }
      }
    ]
    const client = await getCogniteClient()
    await client.events.create(event)
  }
}

export interface ApproveInspectionStartEventProps {
  id: number
  groups: string[]
  name: string
}

export const approveInspectionStartEvent = async (
  props: ApproveInspectionStartEventProps
): Promise<void> => {
  if (!props.groups.some((group) => group === APPROVER_GROUP_ID)) {
    throw new Error('You are not authorized to approve this event.')
  }

  const client = await getCogniteClient()
  const event = (await client.events.retrieve([{ id: props.id }]))[0]
  await client.events
    .update([
      {
        id: props.id,
        update: {
          metadata: {
            set: {
              ...event?.metadata,
              isApproved: 'true',
              approverName: props.name,
              // 2023/7/12 13:37:40 という形式で保存
              approveDate: new Date().toLocaleString('ja-JP', {
                timeZone: 'Asia/Tokyo'
              })
            }
          }
        }
      }
    ])
    .catch((error) => {
      console.log(error)
      throw new Error('approveInspectionStartEvent is failed.')
    })
}

export const getIdToken = async (): Promise<string> => {
  const client = await getCogniteClient()
  return (await client.get('/api/v1/token/inspect')).data as string
}

export const getParsedIdTokenPayload = async (): Promise<any> => {
  const base64Url = (await getIdToken()).split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
