import { FC, useState, createContext, useContext } from 'react'
import { TypeIdSchema } from 'api/backendApi'

// Contextオブジェクトを生成する
// 無駄なレンダリングを避けるため、値とsetterに分けてcontextを定義
// https://zenn.dev/yuta_ura/articles/react-context-api
export const inspectionTypesContext = createContext<TypeIdSchema[]>([])
export const setInspectionTypesContext = createContext<
  React.Dispatch<React.SetStateAction<TypeIdSchema[]>>
>(() => undefined)

// 生成したContextオブジェクトのProviderを定義する
export const InspectionTypesProvider: FC = ({ children }) => {
  const [inspectionTypes, setInspectionTypes] = useState<TypeIdSchema[]>([])

  return (
    <inspectionTypesContext.Provider value={inspectionTypes}>
      <setInspectionTypesContext.Provider value={setInspectionTypes}>
        {children}
      </setInspectionTypesContext.Provider>
    </inspectionTypesContext.Provider>
  )
}

export const useInspectionTypes = (): TypeIdSchema[] =>
  useContext(inspectionTypesContext)
export const useSetInspectionTypes = (): React.Dispatch<
  React.SetStateAction<TypeIdSchema[]>
> => useContext(setInspectionTypesContext)
