import { CogniteEvent } from '@cognite/sdk/dist/src'
import { getRegisterdNote, MachinesSchema } from 'api/backendApi'
import { getNotesByMachineId } from 'api/cogniteApi'
import { useIdToken } from 'contexts/IdTokenContext'
import { useState, useEffect } from 'react'

export interface useTempMachineNotesProps {
  machineList: MachinesSchema[]
}

export interface useMachineNotesReturn {
  machineNotes: MachineNote[]
  setMachineNotes: React.Dispatch<React.SetStateAction<MachineNote[]>>
}

export interface MachineNote {
  machineId: number
  machineName: string
  note: string
  assetId: number
}

export const useTempMachineNotes = (
  props: useTempMachineNotesProps
): useMachineNotesReturn => {
  const [machineNotes, setMachineNotes] = useState<MachineNote[]>([])
  const idToken = useIdToken()

  useEffect(() => {
    const fetchAndSetMachineNotes = async (): Promise<void> => {
      // 本登録前の場合、仮登録データを取得する
      const registeredNotes = await Promise.all(
        props.machineList.map(async (machine) => {
          return await getRegisterdNote({
            idToken,
            machineId: machine.machineId
          })
        })
      )
      // machineListと同一長の空文字列配列を生成し、machineNotesにsetする
      setMachineNotes(
        props.machineList.map((machine, index) => ({
          machineId: machine.machineId,
          machineName: machine.machineName,
          note: registeredNotes[index] != null ? registeredNotes[index] : '',
          assetId: machine.assetId
        }))
      )
    }

    if (props.machineList.length === 0 || idToken === '') {
      return
    }
    fetchAndSetMachineNotes().catch((error) => {
      console.log(error)
      throw new Error('fetchSetMachineNotes is failed')
    })

    return () => {
      setMachineNotes([])
    }
  }, [idToken, props.machineList])

  return { machineNotes, setMachineNotes }
}

export interface useMachineNotesProps {
  machineList: MachinesSchema[]
  startEvent: CogniteEvent | null
}

export const useMachineNotes = (
  props: useMachineNotesProps
): useMachineNotesReturn => {
  const [machineNotes, setMachineNotes] = useState<MachineNote[]>([])
  const idToken = useIdToken()

  useEffect(() => {
    if (
      props.startEvent == null ||
      props.startEvent.startTime == null ||
      props.startEvent.endTime == null ||
      props.startEvent.id == null ||
      props.machineList.length === 0 ||
      idToken === ''
    ) {
      return
    }

    const fetchAndSetMachineNotes = async (): Promise<void> => {
      const registeredNotes = await getNotesByMachineId({
        machineIds: props.machineList.map((machine) =>
          String(machine.machineId)
        ),
        // 引数の存在チェック済みなので0にはならないはず
        startTime: props?.startEvent?.startTime ?? 0,
        endTime: props?.startEvent?.endTime ?? 0,
        eventId: String(props?.startEvent?.id)
      })

      // machineListと同一長の空文字列配列を生成し、machineNotesにsetする
      const formattedNotes = props.machineList.map((machine, index) => ({
        machineId: machine.machineId,
        machineName: machine.machineName,
        note: registeredNotes[index] != null ? registeredNotes[index] : '',
        assetId: machine.assetId
      }))
      setMachineNotes(formattedNotes)
    }

    fetchAndSetMachineNotes().catch((error) => {
      console.log(error)
      throw new Error('fetchSetMachineNotes is failed')
    })

    return () => {
      setMachineNotes([])
    }
  }, [idToken, props.machineList, props.startEvent])

  return { machineNotes, setMachineNotes }
}
