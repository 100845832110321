import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import {
  Paper,
  ListItem,
  Grid,
  InputLabel,
  FormControl,
  Input,
  InputAdornment
} from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { Alertflag, ItemsSchema } from 'api/backendApi'
import { InputTask } from 'components/Molecules/InputTask'
import { useInspectionEvents } from 'hooks/useInspectionEvents'
import { Timestamp } from '@cognite/sdk'

import { usePreviousResult } from 'hooks/usePreviousResult'
import { useLatestValue } from 'hooks/useLatestValue'
import { UpdateMachineTaskListProps } from 'hooks/useMachineTaskList'
interface createThresholdStringProps {
  minValue: number | undefined
  maxValue: number | undefined
}

// 点検項目の下部に表示する管理値の表示文字列を生成
const createThresholdString = (props: createThresholdStringProps): string => {
  // undefinedをNumber()するとNaNに変換される
  const minValue = Number(props.minValue)
  const maxValue = Number(props.maxValue)
  // 最低、最大いずれも定義されていない場合
  if (isNaN(minValue) && isNaN(maxValue)) {
    return ''
    // 最大値のみ定義されている場合
  } else if (isNaN(minValue)) {
    return `管理値 ~${maxValue}`
    // 最小値のみ定義されている場合
  } else if (isNaN(maxValue)) {
    return `管理値 ${minValue}~`
    // 最大、最小値いずれも定義されている場合
  } else {
    return `管理値 ${minValue}~${maxValue}`
  }
}

export interface TaskListItemProps {
  task: ItemsSchema
  taskIndex: number
  machineIndex: number
  onTimeseriesChangeFunc: (props: UpdateMachineTaskListProps) => void
}

interface InputTaskCardProps {
  task: ItemsSchema
  taskIndex: number
  machineIndex: number
  onTimeseriesChangeFunc: (props: UpdateMachineTaskListProps) => void
  latestValue: string
}

const InputTaskCard: React.FC<InputTaskCardProps> = (props) => {
  if (props.task.ioMode === 1) {
    return (
      <InputTask
        task={props.task}
        taskIndex={props.taskIndex}
        machineIndex={props.machineIndex}
        onTimeseriesChangeFunc={props.onTimeseriesChangeFunc}
      />
    )
  } else {
    // DCSデータを表示する場合
    return (
      <Input
        id="standard-adornment-amount"
        data-testid="number-output-task"
        disabled
        value={props.latestValue}
        endAdornment={
          <InputAdornment position="end">
            {props.task.unit} (現在の値)
          </InputAdornment>
        }
      />
    )
  }
}

export const TaskListItem: React.FC<TaskListItemProps> = (props) => {
  const { inspectionEvents } = useInspectionEvents({ maxEvents: 1 })
  const [inspectionDate, setInspectionDate] = useState<Timestamp | undefined>()

  useEffect(() => {
    if (inspectionEvents.length === 0) {
      return
    }
    // previousInspectionDateの値に変化がない場合、更新は行わない。
    // TODO useEffectの仕様上、inspectionEventsに変化がない場合はcallされないはずだが、毎回呼び出されてしまう
    if (inspectionEvents[0].startTime !== inspectionDate) {
      setInspectionDate(inspectionEvents[0].startTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionEvents])

  // 管理値の文章を保存
  const [thresholdString, setThresholdString] = useState<string>('')
  useEffect(() => {
    setThresholdString(
      createThresholdString({
        minValue: props.task.minimumValue,
        maxValue: props.task.maximumValue
      })
    )
  }, [props.task.maximumValue, props.task.minimumValue])

  const { previousValue, previousDate, previousTime } = usePreviousResult({
    inspectionDate,
    task: props.task
  })

  // DCSデータ表示に用いる最新の点検結果を管理。
  const { latestValue } = useLatestValue({
    externalId: props.task.cogniteExternalId
  })

  const InputTaskNotes: React.FC = () => {
    return (
      <Grid item>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography color="secondary">{thresholdString}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {previousDate + ' ' + previousTime + '   ' + previousValue}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Paper
      sx={{
        margin: '16px 0px',
        backgroundColor: 'primary',
        overflow: 'hidden', // 追加: コンテンツがPaperから溢れないようにする
        ...(props.task.alertflag != null &&
          props.task.alertflag === Alertflag.On && {
            backgroundColor: '#FFF0F5'
          })
      }}
    >
      <ListItem key={props.task.taskName}>
        <Grid container direction="column">
          <Grid item>
            <InputLabel>{props.task.taskName}</InputLabel>
          </Grid>
          <Grid item>
            <InputLabel
              sx={{
                fontSize: '0.8em', // 文字サイズを小さくする
                wordWrap: 'break-word', // 長いテキストを折り返す
                maxWidth: '100%', // Paperコンポーネントの幅内に収まるようにする
                whiteSpace: 'pre-wrap' // 改行を反映する
              }}
            >
              {props.task.taskMemo}
            </InputLabel>
          </Grid>
          <Grid item>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputTaskCard
                task={props.task}
                taskIndex={props.taskIndex}
                machineIndex={props.machineIndex}
                onTimeseriesChangeFunc={props.onTimeseriesChangeFunc}
                latestValue={latestValue}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <InputTaskNotes />
          </Grid>
          <Grid item>
            <ListItemButton
              dense={true}
              sx={{
                color: '#005FFF',
                fontWeight: 'bold',
                borderRadius: 1,
                width: '100%',
                m: 0.5,
                border: '1px solid #e0e0e0'
              }}
              onClick={() =>
                window.open(
                  `https://infield.asia-northeast1-1.cogniteapp.com/ak-h2/fh2r/asset/${props.task.assetId}/overview/`,
                  '_blank'
                )
              }
            >
              <Grid container justifyContent="center">
                <Grid item>
                  <ListItemText primary="機器情報 詳細" />
                </Grid>
              </Grid>
            </ListItemButton>
          </Grid>
        </Grid>
      </ListItem>
    </Paper>
  )
}
