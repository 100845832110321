import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { useEventIdParam } from 'hooks/useUrlParam'
import { CogniteEvent, Timestamp } from '@cognite/sdk'
import {
  getEventsById,
  getMultipleDataPointsByStartAndEndTime,
  getNotesByMachineId
} from 'api/cogniteApi'
import { Loading } from 'components/Atoms/Loading'
import {
  Eventflag,
  getInspectionTypeName,
  getSheetFormat,
  placeType,
  taskType
} from 'api/backendApi'
import { createTheme, styled, ThemeProvider } from '@mui/material'
import { useIdToken, useSetIdToken } from 'contexts/IdTokenContext'
import { useAuthUser } from 'hooks/useAuthUser'
import { useInspectionTypes } from 'contexts/InspectionTypesContext'
import { usePlaceText } from 'hooks/useEnvironmentText'

const theme = createTheme({
  typography: {
    fontSize: 10
  }
})

// 該当するタスクが管理値範囲外かを判定する
const getIsAlarm = (task: taskType): boolean => {
  const numericResult = Number(task.result)
  const minControlValue = task.controlValue.split('~')[0]
  const maxControlValue = task.controlValue.split('~').pop()
  const numericMinControlValue =
    minControlValue !== '' ? Number(minControlValue) : undefined
  const numericMaxControlValue =
    maxControlValue !== '' ? Number(maxControlValue) : undefined
  const minIsAlarm =
    numericMinControlValue != null
      ? numericResult < numericMinControlValue
      : false
  const maxIsAlarm =
    numericMaxControlValue != null
      ? numericResult > numericMaxControlValue
      : false
  const isAlarm = minIsAlarm || maxIsAlarm

  return isAlarm
}

export const ResultSheet: React.FC = () => {
  // 点検シートのフォーマットを取得
  // contextから以前使用していたidTokenを取得
  // 非同期的に新しいidTokenも取得し、idTokenにsetする
  // これにより、idToken取得を待たずにAPIコールが可能になる
  const { newIdToken } = useAuthUser()
  const idToken = useIdToken()
  const setIdToken = useSetIdToken()
  useEffect(() => {
    setIdToken(newIdToken)
  }, [newIdToken, setIdToken])
  const { eventId } = useEventIdParam()
  const placeText = usePlaceText()

  // 対応する点検開始イベントの定義
  const [event, setEvent] = useState<CogniteEvent | null>(null)
  // パスパラメータで渡されるeventIdから対応する点検開始イベントを取得する
  useEffect(() => {
    if (eventId === 0) {
      return
    }
    getEventsById({ eventId })
      .then((result) => {
        setEvent(result)
      })
      .catch((error) => {
        console.log(error)
        throw new Error('retrieveEventsById is failed')
      })
    return () => {
      setEvent(null)
    }
  }, [eventId])

  // 点検シート表示用に点検者名、点検日時、点検種別を定義
  const [inspectorName, setInspectorName] = useState<string>('')
  const [inspectionDate, setInspectionDate] = useState<string>('')
  const [approverName, setApproverName] = useState<string>('')
  const [approveDate, setApproveDate] = useState<string>('')
  const [startTime, setStartTime] = useState<Timestamp | null>(null)
  const [endTime, setEndTime] = useState<Timestamp | null>(null)
  const [isInspectionAlart, setIsInspectionAlart] = useState(false)
  // 対応する点検開始イベントから点検者名、点検日時を取得
  useEffect(() => {
    if (
      event?.startTime != null &&
      event?.endTime != null &&
      event?.metadata?.inspectorName != null &&
      event?.metadata?.inspectorName !== ''
    ) {
      setStartTime(event.startTime)
      setEndTime(event.endTime)
      setInspectorName(event.metadata?.inspectorName)
      // Dateオブジェクトをそのまま表示は出来ないため、string型に変換する
      // 日付
      const startDate = new Date(event.startTime).toLocaleDateString('ja-JP')
      // 開始時刻
      const startTime = new Date(event.startTime).toLocaleTimeString('ja-JP', {
        hour12: false
      })
      // 終了時刻
      const endTime = new Date(event.endTime).toLocaleTimeString('ja-JP', {
        hour12: false
      })
      setInspectionDate(startDate + ' ' + startTime + ' ~ ' + endTime)
      // alertflagが存在しない場合、アラート無しとして扱う
      setIsInspectionAlart(Boolean(event.metadata.alertflag))
      setApproverName(event?.metadata?.approverName ?? '')
      setApproveDate(event?.metadata?.approveDate ?? '')
    }

    return () => {
      setInspectorName('')
      setInspectionDate('')
      setStartTime(null)
      setEndTime(null)
      setIsInspectionAlart(false)
    }
  }, [event])

  const [versionId, setVersionId] = useState<string>('')
  useEffect(() => {
    if (event?.metadata != null && event.metadata.versionId !== '') {
      setVersionId(event.metadata.versionId)
    }
  }, [event])

  const [inspectionTypeName, setInspectionTypeName] = useState<string>('')
  // const setInspectionTypes = useSetInspectionTypes()
  // もしかしたらここにinspectiontypeのfetch & setが必要かも
  const inspectionTypes = useInspectionTypes()
  useEffect(() => {
    if (versionId === '' || inspectionTypes.length === 0 || idToken === '') {
      return
    }

    const setTypeName = async (): Promise<void> => {
      const type = (
        await getInspectionTypeName({
          idToken,
          versionId
        })
      )[0]
      const typeName = type.type
      setInspectionTypeName(typeName)
    }
    void setTypeName()
  }, [idToken, inspectionTypes, versionId])

  const [sheetFormat, setSheetFormat] = useState<placeType[]>([])
  useEffect(() => {
    if (idToken !== '') {
      getSheetFormat({
        versionId,
        idToken
      })
        .then((result) => {
          setSheetFormat(result)
        })
        .catch((error) => {
          console.log(error)
          throw new Error('getSheetFormat is failed')
        })
    }
    return () => {
      setSheetFormat([])
    }
  }, [idToken, versionId])

  // 点検シート及び点検結果を格納する変数を定義
  const [sheetData, setSheetData] = useState<placeType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    // 点検開始、終了時刻が未定義の場合及びsheetFormatが更新されていない場合、
    // 無駄なAPIコールをしないために早期リターンする
    if (startTime == null || endTime == null || sheetFormat == null) {
      return
    }

    interface CreateResultProps {
      data: string
      task: taskType
    }
    const createResult = (props: CreateResultProps): string => {
      // 0-9に対応する表示を意味する列名
      // keyofをつけないと、props.task[keyName]でエラーが発生する
      const displayTable: Array<keyof taskType> = [
        'zeroDisplay',
        'oneDisplay',
        'twoDisplay'
      ]

      if (props.task.eventflag === Eventflag.InputNumber) {
        return String(props.data)
      } else if (props.task.eventflag === Eventflag.Select) {
        // props.dataが空文字列の状態でNumberにキャストすると0になってしまう。そのため、事前に空文字列をreturnする
        // props.data = 0の場合があるので、if (!props.data)とはできない
        if (props.data === '') {
          return ''
        }
        try {
          // data がnumberまたは0~9のstringであることを前提としている。
          // それ以外の場合、エラーを発生させる
          const index: number = Number(props.data)
          const showValue = props.task[displayTable[index]]
          if (typeof showValue !== 'undefined') {
            return String(showValue)
          } else {
            return ''
          }
        } catch (error) {
          console.log(error)
          throw new Error('given data format is invalid (not 0 ~ 2)')
        }
      } else {
        throw new Error('eventflag is invalid')
      }
    }

    const writeResult = async (): Promise<void> => {
      const taskExternalIds: string[] = []
      const machineExternalIds: string[] = []
      // resultDataのフォーマットから各点検項目ごとのexternalIdを取得
      sheetFormat.forEach((place) => {
        place.machine.forEach((machine) => {
          machineExternalIds.push(machine.machineId)
          machine.task.forEach((task) => {
            taskExternalIds.push(task.externalId)
          })
        })
      })
      if (taskExternalIds.length === 0 || machineExternalIds.length === 0) {
        return
      }

      // 現在のsheetFormatを取得し、該当タスクの値を上書きしてsetし直す
      const tempSheetData = sheetFormat
      // externalIdsの要素がない場合、以下の処理は行わない
      await getMultipleDataPointsByStartAndEndTime({
        externalIds: taskExternalIds,
        startTime,
        endTime
      })
        .then((dataPoints) => {
          let count: number = 0
          // externalIdsを生成したときと同じ順番でループを回すことで、
          // dataPointsの順番とexternalIdsの順番が対応したものとして扱うことができる
          // 全ての点検項目にTimeseriesが紐付いている場合のみ成立する
          // 点検項目でTimeseriesに登録しないものが現れた場合は修正する
          tempSheetData.forEach((place) => {
            place.machine.forEach((machine) => {
              machine.task.forEach((task) => {
                // 選択肢形式の点検は結果の数値を文字に変換、数値入力形式はそのまま結果とする
                task.result = createResult({
                  data: dataPoints[count],
                  task
                })
                count++
              })
            })
          })
        })
        .catch((error) => {
          console.log(error)
          throw new Error('getMultipleDataPointsByStartAndEndTime is failed.')
        })

      await getNotesByMachineId({
        machineIds: machineExternalIds,
        startTime,
        // 備考イベントなどの生成時刻がmsオーダーで点検終了時刻を上回ることがあるので、10秒加算する
        endTime: new Date(endTime).getTime() + 10000,
        eventId: String(eventId)
      })
        .then((notes) => {
          let count: number = 0
          // externalIdsを生成したときと同じ順番でループを回すことで、
          // dataPointsの順番とexternalIdsの順番が対応したものとして扱うことができる
          tempSheetData.forEach((place) => {
            place.machine.forEach((machine) => {
              machine.notes = notes[count]
              count++
            })
          })
        })
        .catch((error) => {
          console.log(error)
          throw new Error('getNotesByMachineId is failed.')
        })

      setSheetData(tempSheetData)
      setIsLoading(false)
    }

    writeResult().catch((error) => {
      console.log(error)
      throw new Error('writeResult is failed')
    })
    return () => {
      setSheetData([])
    }
  }, [endTime, eventId, sheetFormat, startTime])

  const BreakTableCell = styled(TableCell)({
    wordBreak: 'break-all',
    lineHeight: '9px'
  })

  const HeightTableCell = styled(TableCell)({
    lineHeight: '7px',
    minWidth: '70px'
  })

  if (isLoading) {
    return <Loading isLoading={isLoading} />
    // sheetData.map()がまず行われシートが描画されるが、その後sheetDataを更新しても表示は更新されない。
    // そのため、sheetDataを更新し終えてから描画するようにする。
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Paper sx={{ width: '810px', displayPrint: 'block' }}>
          <TableContainer component={Paper} sx={{ width: '92.5%', ml: '7.5%' }}>
            <Table size="small" aria-label="simple table" sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  <BreakTableCell colSpan={1} sx={{ lineHeight: '9px' }}>
                    {placeText}定常管理シート <br /> ({inspectionTypeName})
                  </BreakTableCell>
                  <HeightTableCell colSpan={1} align="right">
                    {'点検者 ' + inspectorName}
                  </HeightTableCell>
                  <HeightTableCell colSpan={4} align="right">
                    {'点検時刻 ' + inspectionDate}
                  </HeightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <HeightTableCell colSpan={1} />
                  <HeightTableCell colSpan={1} align="right">
                    {'承認者 ' + approverName}
                  </HeightTableCell>
                  <HeightTableCell colSpan={4} align="right">
                    {'承認時刻 ' + approveDate}
                  </HeightTableCell>
                </TableRow>
              </TableBody>
              {sheetData.map((place) => (
                <React.Fragment key={place.placeName + '-div'}>
                  <TableHead key={place.placeName + '-head'}>
                    <TableRow key={place.placeName}>
                      <HeightTableCell
                        colSpan={2}
                        sx={{ fontWeight: '500' }}
                        key={place.placeName}
                      >
                        {place.placeName}
                      </HeightTableCell>
                      <HeightTableCell
                        sx={{
                          fontWeight: '500'
                        }}
                        key={'unit'}
                      >
                        単位
                      </HeightTableCell>
                      <HeightTableCell
                        sx={{
                          fontWeight: '500'
                        }}
                        key={'controlValue'}
                      >
                        管理値
                      </HeightTableCell>
                      <HeightTableCell
                        sx={{ fontWeight: '500' }}
                        key={'result'}
                      >
                        記録
                      </HeightTableCell>
                      <HeightTableCell sx={{ fontWeight: '500' }} key={'notes'}>
                        備考
                      </HeightTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody key={place.placeName + '-body'}>
                    {place.machine.map((machine) => (
                      <React.Fragment key={machine.machineId + '-div'}>
                        <TableRow key={machine.machineId}>
                          <BreakTableCell
                            key={machine.machineId}
                            rowSpan={machine.task.length + 1}
                          >
                            {/* 川崎パイロット向けに先頭のCK.を削除 */}
                            {machine.machineName.replace(/^CK\./, '')}
                          </BreakTableCell>
                        </TableRow>
                        {machine.task.map((task, idx) => {
                          const isAlarm = isInspectionAlart
                            ? getIsAlarm(task)
                            : false
                          return (
                            <TableRow key={task.taskName + '-row'}>
                              <BreakTableCell
                                key={'taskName'}
                                sx={{
                                  ...(isAlarm && { color: 'red' })
                                }}
                              >
                                {task.taskName}
                              </BreakTableCell>
                              <BreakTableCell
                                key={'unit'}
                                sx={{
                                  ...(isAlarm && { color: 'red' })
                                }}
                              >
                                {task.unit}
                              </BreakTableCell>
                              <BreakTableCell
                                key={'controlValue'}
                                sx={{
                                  ...(isAlarm && { color: 'red' })
                                }}
                              >
                                {task.controlValue}
                              </BreakTableCell>
                              <BreakTableCell
                                sx={{
                                  fontWeight: '600',
                                  ...(isAlarm && { color: 'red' })
                                }}
                                key={'result'}
                              >
                                {task.result}
                              </BreakTableCell>
                              {idx === 0 && (
                                <BreakTableCell
                                  rowSpan={machine.task.length + 1}
                                  key={'notes'}
                                  sx={{
                                    ...(isAlarm && { color: 'red' })
                                  }}
                                >
                                  {machine.notes}
                                </BreakTableCell>
                              )}
                            </TableRow>
                          )
                        })}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </React.Fragment>
              ))}
            </Table>
          </TableContainer>
        </Paper>
      </ThemeProvider>
    )
  }
}
