import { MachinesSchema, ItemsSchema, Alertflag } from 'api/backendApi'
import { getMultipleDataPointsByStartAndEndTime } from 'api/cogniteApi'
import { useCallback, useEffect, useState } from 'react'
import { useMachineTaskList } from './useMachineTaskList'

export interface UpdateModificationMachineTaskListProps {
  machineIndex: number
  taskIndex: number
  newValue: number | null | undefined
}

export interface UseModificationMachineTaskListProps {
  machineList: MachinesSchema[]
  start: number
  end: number
}

export interface UseModificationMachineTaskListReturn {
  machineTaskList: MachineTask[]
  setMachineTaskList: React.Dispatch<React.SetStateAction<MachineTask[]>>
  updateMachineTaskList: (props: UpdateModificationMachineTaskListProps) => void
}

export interface MachineTask {
  machineName: string
  machineMemo: string | null
  tasks: ItemsSchema[]
}

export const useModificationMachineTaskList = (
  props: UseModificationMachineTaskListProps
): UseModificationMachineTaskListReturn => {
  const { machineTaskList: rawMachineTaskList } = useMachineTaskList({
    machineList: props.machineList
  })

  /**
   * taskについて、alert状態かの判断を行う
   *
   * @param {ItemsSchema} task
   * @return {boolean}
   */
  const isAlert = (task: ItemsSchema): boolean => {
    // 値が最小値以下の場合、trueを返す
    if (
      task.minimumValue != null &&
      task.value != null &&
      task.value < task.minimumValue
    ) {
      return true
    }
    // 値が最大値以上の場合、trueを返す
    if (
      task.maximumValue != null &&
      task.value != null &&
      task.value > task.maximumValue
    ) {
      return true
    }
    // 選択肢がevaluationflagに該当する場合、trueを返す
    if (
      task.eventflag === 1 &&
      task.value != null &&
      task.evaluationflag != null &&
      (task.evaluationflag & (0x01 << task.value)) !== 0
    ) {
      return true
    }
    return false
  }

  const setAlertflag = useCallback((task: ItemsSchema): ItemsSchema => {
    if (isAlert(task)) {
      task.alertflag = Alertflag.On
    } else {
      task.alertflag = Alertflag.Off
    }
    return task
  }, [])

  const [machineTaskList, setMachineTaskList] = useState<MachineTask[]>([])

  // TODO いい感じにfetchした値でvalueを上書きしたmachineTaskListをreturnする
  useEffect(() => {
    const setNewValue = async (): Promise<void> => {
      // map関数をasyncにしたため、返り値がPromiseの配列となってしまう。それら全てのPromiseをresolveするため、Promise.all()をコールしている。
      const newMachineTaskList = await Promise.all(
        rawMachineTaskList.map(async (machineTask): Promise<MachineTask> => {
          return {
            ...machineTask,
            tasks: await Promise.all(
              machineTask.tasks.map(async (task): Promise<ItemsSchema> => {
                const datapoints = await getMultipleDataPointsByStartAndEndTime(
                  {
                    externalIds: [task.cogniteExternalId],
                    startTime: props.start,
                    endTime: props.end
                  }
                ).catch((error) => {
                  console.log(error)
                  throw new Error('getMultipleDataPointsByAndEndTime is failed')
                })
                return { ...task, value: Number(datapoints[0]) }
              })
            )
          }
        })
      )
      setMachineTaskList(newMachineTaskList)
    }
    void setNewValue()
  }, [props.end, props.start, rawMachineTaskList])

  /**
   * machineIndex, taskIndexを元にmachineTaskListをupdateする関数
   *
   * @param {UpdateModificationMachineTaskListProps} props
   */
  const updateMachineTaskList = (
    props: UpdateModificationMachineTaskListProps
  ): void => {
    if (machineTaskList.length === 0) {
      return
    }

    const newMachineTaskList = machineTaskList.map((machine, machineIndex) => {
      if (machineIndex !== props.machineIndex) {
        return machine
      }
      const newMachine: MachineTask = {
        machineName: machine.machineName,
        machineMemo: machine.machineMemo != null ? machine.machineMemo : '',
        tasks: machine.tasks.map((task, taskIndex) => {
          if (taskIndex !== props.taskIndex) {
            return task
          }
          const valueSetTask: ItemsSchema = {
            ...task,
            value: props.newValue
          }
          const alertflagSetTask: ItemsSchema = setAlertflag(valueSetTask)
          return alertflagSetTask
        })
      }
      return newMachine
    })
    setMachineTaskList(newMachineTaskList)
  }

  return { machineTaskList, setMachineTaskList, updateMachineTaskList }
}
