import { EnvConst } from 'consts/commonConst'

// prod環境
export const envConst: EnvConst = {
  ENVIRONMENT: 'monthly-production',
  DATASET_ID: 392019516605940,
  FACILITY_NAME: '浪江工場',
  BACKEND_API_ROOT_URL:
    'https://djeu0e0tn8.execute-api.ap-northeast-1.amazonaws.com',
  CDF_PROJECT: 'ak-h2'
}
