import React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useEnvironmentText } from 'hooks/useEnvironmentText'

interface Props {
  instance: IPublicClientApplication
  scopes: string[]
}

const theme = createTheme()

const Login: React.FC<Props> = (props) => {
  const scopes: string[] = props.scopes
  const environmentText = useEnvironmentText()

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ログイン
          </Typography>
          <Typography component="h4" variant="h5">
            {environmentText}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                props.instance
                  .loginRedirect({
                    prompt: 'select_account',
                    scopes
                  })
                  .catch((error) => {
                    console.log(error)
                    throw new Error('loginRedirect is failed')
                  })
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default Login
