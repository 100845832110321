import React from 'react'
import { Grid } from '@material-ui/core'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useTypeNameParam } from 'hooks/useUrlParam'
import { usePlaceGroupList } from 'hooks/usePlaceGroupList'
import { Button, Skeleton, Typography } from '@mui/material'
import { UpdateCogniteSnackBar } from 'components/Molecules/UpdateCogniteSnackBar'

const theme = createTheme()

/**
 * グループ名一覧を表示するコンポーネント
 *
 * @return {JSX.Element}
 */
const ModificationGroupList: React.FC = () => {
  const navigate = useNavigate()
  // URLから種別に対応したパラメータを取得
  const { typeName } = useTypeNameParam()
  const handleToTaskListPage = (props: number): void => {
    navigate(`/modification/inspectionInput/${typeName}/${props}`)
  }
  const { placeList, placeGroupList } = usePlaceGroupList()

  interface GroupCardProps {
    groupId: number
    groupName: string
    timestamp?: string
  }
  /**
   * 点検項目ページに遷移するグルーポ名が書かれたカードコンポーネント
   *
   * @param {GroupCardProps} props
   * @return {JSX.Element}
   */
  const GroupCard: React.FC<GroupCardProps> = (props) => {
    return (
      <Paper style={{ margin: '16px 0px' }} key={props.groupId}>
        <ListItem key={props.groupId}>
          <ListItemButton
            onClick={() => handleToTaskListPage(props.groupId)}
            key={props.groupId}
          >
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Grid item xs={7}>
                <ListItemText
                  primary={`${props.groupName}`}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                    fontSize: 'large'
                  }}
                  key={props.groupId}
                />
              </Grid>
            </Grid>
          </ListItemButton>
        </ListItem>
      </Paper>
    )
  }

  /**
   * 場所名、グループカード、skeletonを表示するコンポーネント
   *
   * @return {JSX.Element}
   */
  const GroupButtonsWithSkeleton: React.FC = () => {
    if (placeList.length > 0) {
      return (
        <List>
          {placeList.map((place) => (
            <li key={place}>
              <ul key={place}>
                <ListSubheader
                  color="primary"
                  sx={{
                    fontSize: 'x-large',
                    backgroundColor: '#f9f9ff'
                  }}
                  key={place}
                >{`${place}`}</ListSubheader>
                {placeGroupList[place].map((group) => (
                  <GroupCard
                    groupId={group.groupId}
                    groupName={group.groupName}
                    timestamp={group.timestamp}
                  />
                ))}
              </ul>
            </li>
          ))}
        </List>
      )
    } else {
      // ローディング中の場合、skeletonを表示させる
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={300}
              height={45}
            />
          </Grid>
          <Grid item>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={300}
              height={80}
              sx={{ margin: 2 }}
            />
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            alignItems: 'center'
          }}
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">過去結果修正</Typography>
            </Grid>
          </Grid>

          <GroupButtonsWithSkeleton />
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                onClick={() => navigate(`/inspectionResultsList`)}
              >
                修正完了
              </Button>
            </Grid>
            <Grid item>
              <UpdateCogniteSnackBar />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default ModificationGroupList
