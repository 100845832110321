import React, { forwardRef } from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

export interface SuccessSnackBarProps {
  message: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: (event?: React.SyntheticEvent, reason?: string) => void
}

export const SuccessSnackBar: React.FC<SuccessSnackBarProps> = (props) => {
  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={1000}
      onClose={() => props.setOpen(false)}
    >
      <Alert
        onClose={props.handleClose}
        severity="success"
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}
