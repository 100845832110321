import { Environment } from 'consts/commonConst'
import { envConst } from 'consts/envConst'

export const useEnvironmentText = (): string => {
  const env: Environment = envConst.ENVIRONMENT
  if (env === 'development') {
    return '水電解点検アプリ 開発環境'
  } else if (env === 'test') {
    return '水電解点検アプリ テスト環境'
  } else if (env === 'production') {
    return 'FH2R 日常点検アプリ'
  } else if (env === 'monthly-production') {
    return 'FH2R 月例点検アプリ'
  } else if (env === 'rem-daily') {
    return 'REM実証棟 日常点検アプリ'
  } else if (env === 'kp-daily') {
    return '川崎パイロット 日常点検アプリ'
  } else {
    // union型の網羅チェック
    // 参考リンク: https://typescript-jp.gitbook.io/deep-dive/type-system/discriminated-unions#chekkuexhaustive-checks
    const _exhaustiveCheck: never = env
    return _exhaustiveCheck
  }
}

export const usePlaceText = (): string => {
  const env: Environment = envConst.ENVIRONMENT
  if (env === 'development') {
    return '浪江大型'
  } else if (env === 'test') {
    return '浪江大型'
  } else if (env === 'production') {
    return '浪江大型'
  } else if (env === 'monthly-production') {
    return '浪江大型'
  } else if (env === 'rem-daily') {
    return 'REM実証棟'
  } else if (env === 'kp-daily') {
    return '川崎パイロット'
  } else {
    const _exhaustiveCheck: never = env
    return _exhaustiveCheck
  }
}
