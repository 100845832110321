import { useInspectionTypes } from 'contexts/InspectionTypesContext'
import { useEffect, useState } from 'react'
import { useTypeNameParam } from './useUrlParam'

interface useTargetIndexReturn {
  targetIndex: number | undefined
}

/**
 * 運転手別一覧のうち、いま点検を行っている運転種別のindexを取得するフック。
 * HOME画面で使用することはできない
 *
 * @return {useTargetIndexReturn}
 */
export const useTargetIndex = (): useTargetIndexReturn => {
  const inspectionTypes = useInspectionTypes()
  // 種別を対応するIDに変換
  const { typeName } = useTypeNameParam()

  const [targetIndex, setTargetIndex] = useState<number>()
  // 配列の中身に同一の要素が存在するかを確認
  const existsSameValue = (array: string[]): boolean => {
    const set = new Set(array)
    return set.size !== array.length
  }
  // versionId, targetIndexの設定
  useEffect(() => {
    if (inspectionTypes.length === 0) {
      return
    }

    const types: string[] = inspectionTypes.map((element) => element.type)
    if (existsSameValue(types)) {
      throw new Error('same type is found in inspectionTypes')
    }
    const index = types.indexOf(typeName)
    setTargetIndex(index)
  }, [inspectionTypes, typeName])

  return {
    targetIndex
  }
}
