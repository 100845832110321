import React, { useState } from 'react'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'

import { ChoiceInputTask } from 'components/Molecules/ChoiceInputTask'
import { Eventflag, ItemsSchema } from 'api/backendApi'
import { UpdateMachineTaskListProps } from 'hooks/useMachineTaskList'

export interface InputTaskProps {
  task: ItemsSchema
  taskIndex: number
  machineIndex: number
  onTimeseriesChangeFunc: (props: UpdateMachineTaskListProps) => void
}

export const InputTask: React.FC<InputTaskProps> = (props: InputTaskProps) => {
  // taskIdsにtask.taskItemIdが含まれていない場合、何もしない
  const [analogCurrentValue, setAnalogCurrentValue] = useState<number | null>(
    props.task.value != null ? props.task.value : null
  )

  // 選択肢の点検項目の場合
  if (props.task.eventflag === Eventflag.Select) {
    return (
      <ChoiceInputTask
        task={props.task}
        taskIndex={props.taskIndex}
        machineIndex={props.machineIndex}
        onTimeseriesChangeFunc={props.onTimeseriesChangeFunc}
      />
    )
    // 数値入力の点検項目の場合
  } else if (props.task.eventflag === Eventflag.InputNumber) {
    return (
      <Input
        id="standard-adornment-amount"
        data-testid="number-input-task"
        type="number"
        onChange={(event) => {
          const newValue =
            event.target.value === '' ? null : Number(event.target.value)
          props.onTimeseriesChangeFunc({
            taskIndex: props.taskIndex,
            machineIndex: props.machineIndex,
            newValue
          })
          setAnalogCurrentValue(newValue)
        }}
        endAdornment={
          <InputAdornment position="end">{props.task.unit}</InputAdornment>
        }
        value={analogCurrentValue}
      />
    )
  } else {
    throw new Error('eventflag is invalid.')
  }
}
